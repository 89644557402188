import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Viewer from "react-viewer";
import Select from "react-select";
import Help from "../common/Help";
import * as actions from "../../store/actions";
import { useTranslation } from "react-i18next";
import { BLACK, GREY } from "../../store/colorCode";
import { styled } from "@mui/system";
import {
  AppBar,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  InputAdornment,
} from "@mui/material";
import TopBar from "../read/TopBar";
import CloseIcon from "@mui/icons-material/Close";
import { capitalize, getAllInfographics } from "../common/utility";
import { languageDataAPI } from "../../store/api";

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  maxWidth: 250,
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
}));

const SearchBox = styled(TextField)(({ theme }) => ({
  marginLeft: "12px",
  width: "100%",
  maxWidth: 280,
  [theme.breakpoints.down("md")]: {
    width: "45%",
    marginLeft: "8px",
    marginRight: "4px",
  },
  "& .MuiInputBase-root": {
    height: "38px",
  },
}));

const FixedContainer = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #f1ecec",
  display: "flex",
  width: "100%",
  padding: "10px 16px",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  position: "fixed",
  top: 64,
  zIndex: 1000,
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "8px",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
}));

const CenterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "8px",
  },
}));

const RightContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexShrink: 0,
}));

const StyledButton = styled(Button)(() => ({
  width: "100%",
  marginBottom: "8px",
  textTransform: "capitalize",
  color: BLACK,
  border: "1px solid " + GREY,
  boxShadow: "1px 1px 1px 1px " + GREY,
  "&:hover": {
    backgroundColor: BLACK + "0a",
  },
}));

const InfographicsPage = (props) => {
  const { infographics, panel1, setValue } = props;
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [allInfographics, setAllInfographics] = useState([]);
  const [filteredInfographics, setFilteredInfographics] = useState([]);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { languageCode } = panel1;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [language]);

  useEffect(() => {
    if (Object.keys(infographics).length === 0) {
      languageDataAPI?.get("languageData.json").then(function (response) {
        const languageData = response.data.map((lang) => ({
          name: lang.language,
          code: lang.langCode,
        }));
        getAllInfographics(languageData, setValue);
      });
    }
  }, [setValue, infographics, languages]);

  useEffect(() => {
    if (Array.isArray(infographics)) {
      const languageList = infographics
        ?.map((item) => {
          const lang = capitalize(item?.language?.name);
          return { value: item?.language?.code, label: lang };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
      setLanguages(languageList);

      if (languageCode) {
        const defaultLanguage = languageList.find(
          (l) => l.value === languageCode
        );
        setLanguage(defaultLanguage || languageList[0]);
      } else {
        setLanguage(languageList[0]);
      }
    }
  }, [infographics, languageCode]);

  useEffect(() => {
    if (language && Object.keys(infographics).length !== 0) {
      const langObj = infographics.find(
        (obj) => obj?.language?.code === language?.value
      );
      if (langObj) {
        const allInfographicsData = langObj.books.flatMap((book) =>
          book.infographics.map((item) => ({
            ...item,
            src: langObj.url + item.fileName,
            alt: item.title,
          }))
        );
        // filter out duplicate fileNames entries from allInfographicsData
        const uniqueInfographicsData = allInfographicsData.filter(
          (v, i, a) => a.findIndex((t) => t.fileName === v.fileName) === i
        );
        setAllInfographics(uniqueInfographicsData);
        setFilteredInfographics(uniqueInfographicsData);
        setUrl(langObj.url);
        setMessage("");
        setLoading(false);
      } else {
        setAllInfographics([]);
        setFilteredInfographics([]);
        setUrl("");
        setMessage(
          t("studyNoInfographicsAvailable", { ref: { lang: language.label } })
        );
        setLoading(false);
      }
    }
  }, [infographics, language, t]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = allInfographics.filter((pic) =>
        pic.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredInfographics(filtered);
    } else {
      setFilteredInfographics(allInfographics);
    }
  }, [searchQuery, allInfographics]);

  function clearTextField() {
    setSearchQuery("");
  }

  return (
    <>
      <AppBar position="fixed">
        <TopBar />
      </AppBar>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          top: 125,
          [theme.breakpoints.down("sm")]: {
            top: 60,
          },
          bottom: 0,
        }}
      >
        {isSmallScreen ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid #f1ecec",
              position: "fixed",
              width: "100%",
              backgroundColor: theme.palette.background.paper,
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                width: "100%",
                paddingBottom: 1,
                borderBottom: "1px solid #f1ecec",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.125rem",
                  margin: "auto",
                }}
              >
                {t("infographicsText")}
              </Typography>
              <Help
                iconStyle={{
                  padding: "1px 1.5px 0",
                  color: BLACK,
                  fontSize: 23,
                  marginRight: 1,
                }}
                url={"infographics"}
                sx={{ position: "absolute", right: 0 }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 1,
              }}
            >
              <StyledSelect
                value={language}
                onChange={(data) => setLanguage(data)}
                options={languages}
                sx={{ flexGrow: 1 }}
              />
              <SearchBox
                placeholder={t("searchPlaceHolderTopbarSmall")}
                variant="outlined"
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                InputProps={{
                  endAdornment: searchQuery && (
                    <InputAdornment>
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={clearTextField}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        ) : (
          <FixedContainer>
            <StyledSelect
              value={language}
              onChange={(data) => setLanguage(data)}
              options={languages}
            />
            <SearchBox
              placeholder={t("studyEnterSearchText")}
              variant="outlined"
              size="small"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              InputProps={{
                endAdornment: searchQuery && (
                  <InputAdornment>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={clearTextField}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <CenterContainer>
              <Typography variant="h4">{t("infographicsText")}</Typography>
            </CenterContainer>
            <RightContainer>
              <Help
                iconStyle={{
                  padding: "1px 1.5px 0",
                  color: BLACK,
                  marginTop: 0.275,
                  fontSize: 23,
                }}
                url={"infographics"}
              />
            </RightContainer>
          </FixedContainer>
        )}
        <Box
          ref={containerRef}
          sx={{
            height: "100%",
            overflowY: "scroll",
            maxHeight: { xs: "630px", sm: "unset" },
            position: "relative",
            width: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0,0,0,.4) #eeeeee95",
            "&::-webkit-scrollbar": {
              width: "0.45em",
            },
            "&::-webkit-scrollbar-track": {
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.4)",
              outline: "1px solid slategrey",
            },
            padding: "16px",
            boxSizing: "border-box",
            [theme.breakpoints.down("sm")]: {
              top: 110,
            },
          }}
        >
          {loading ? (
            <Typography variant="h6" sx={{ paddingLeft: 3.5 }}>
              {t("loadingMessage")}
            </Typography>
          ) : searchQuery && filteredInfographics.length === 0 ? (
            <Typography variant="h6" sx={{ paddingLeft: 3.5 }}>
              {t("noSearchResultsMessage")}
            </Typography>
          ) : filteredInfographics.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: isSmallScreen ? "8px" : "24px",
                justifyContent: isSmallScreen ? "center" : "flex-start",
              }}
            >
              {filteredInfographics.map((pic, index) => (
                <React.Fragment key={index}>
                  {isSmallScreen ? (
                    <StyledButton
                      variant="outlined"
                      onClick={() => {
                        setVisible(true);
                        setActiveIndex(index);
                      }}
                    >
                      {pic.title}
                    </StyledButton>
                  ) : (
                    <Card
                      onClick={() => {
                        setVisible(true);
                        setActiveIndex(index);
                      }}
                      sx={{
                        width: { sm: "20%", lg: "11%" },
                        margin: "0 0 2% 0",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={url + "/thumbs/" + pic.fileName}
                        alt={pic.title}
                        sx={{
                          objectFit: "contain",
                          height: "180px",
                        }}
                      />
                      <CardContent>
                        <Typography
                          noWrap
                          sx={{
                            fontWeight: 600,
                            color: "#1c1c1c",
                            fontSize: 12,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {pic.title}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </React.Fragment>
              ))}
              <Viewer
                zIndex={2500}
                visible={visible}
                onClose={() => {
                  setVisible(false);
                }}
                noNavbar
                noToolbar={isSmallScreen ? true : false}
                images={filteredInfographics.map((pic) => ({
                  src: pic.src,
                  alt: pic.alt,
                }))}
                activeIndex={activeIndex}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h6">{message}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    infographics: state.local.infographics,
    panel1: state.local.panel1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (name, value) =>
      dispatch({ type: actions.SETVALUE, name: name, value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfographicsPage);
