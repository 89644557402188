import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Calendar from "react-calendar";
import Close from "../common/Close";
import Box from "@mui/material/Box";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "react-select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { BLACK, GREY, LIGHTGREY, WHITE } from "../../store/colorCode";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Help from "../common/Help";
import { styled } from "@mui/system";
import { Checkbox, Dialog, ListItemButton, Popover } from "@mui/material";
import { useFirebase } from "react-redux-firebase";
import { useFirebaseConnect } from "react-redux-firebase";
import { connect, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import moment from "moment/moment";
import CreatePlan from "../read/CreatePlan";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import PlanInfo from "./PlanInfo";

const BigTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffd580",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[4],
    border: "1px solid orange",
    fontSize: 16,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: 170,
  padding: "0 15px",
  [theme.breakpoints.only("xs")]: {
    width: 130,
    padding: "0 5px",
    marginLeft: "-15px",
  },
}));

const StyledDatePicker = styled(ReactDatePicker)(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "50%",
  [theme.breakpoints.down("sm")]: { width: 120, marginTop: 6 },
}));

const StyledCalendar = styled(Calendar)(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "50%",
  [theme.breakpoints.down("sm")]: { width: 120, marginTop: 6 },
}));

const Heading = styled("h3")({ paddingLeft: 20 });

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "130px",
  [theme.breakpoints.down("md")]: {
    width: "100px",
  },
}));

const ReadingPlan = (props) => {
  const { setValue1, bookList, mobileView, readingPlans, userDetails, locale } =
    props;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState("");
  const [planData, setPlanData] = useState("");
  const [readingList, setReadingList] = useState([]);
  const [createPlanModel, setCreatePlanModel] = useState(false);
  const [editPlanModel, setEditPlanModel] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [uid, setUid] = useState("");
  const [readingPlanDetail, setReadingPlanDetail] = useState({});
  const [todayDay, setTodayDay] = useState(-1);
  const [completedReading, setCompletedReading] = useState([]);
  const [isPlanStarted, setIsPlanStarted] = useState(false);
  const [differenceDayToday, setDifferenceDayToday] = useState(0);
  const firebase = useFirebase();
  const theme = useTheme();
  const todayCarouselRef = useRef([]);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setUid(userDetails.uid);
  }, [userDetails]);

  useFirebaseConnect(`users/${uid}/readingPlan`);
  const firebaseData = useSelector(
    ({ firebase: { data } }) =>
      data.users && data.users[uid] && data.users[uid].readingPlan
  );

  useEffect(() => {
    if (todayCarouselRef.current[todayDay]) {
      todayCarouselRef.current[todayDay].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [firebaseData, todayDay]);

  const findTodayByStartDate = useCallback(() => {
    if (firebaseData && todayDay === -1) {
      let planStart =
        moment().subtract().toDate() >
        moment(firebaseData.startedDate, "DD/MM/YYYY");
      setIsPlanStarted(planStart);
      // Parse the start date from firebaseData
      let startDate = moment(firebaseData.startedDate, "DD/MM/YYYY");
      // Get the current date
      let endDate = moment();
      // Calculate the difference in days
      let differenceInDays = endDate.diff(startDate, "days");
      setTodayDay(isNaN(differenceInDays) ? 0 : differenceInDays);
      setDifferenceDayToday(isNaN(differenceInDays) ? 0 : differenceInDays);
    }
  }, [firebaseData, todayDay]);

  useEffect(() => {
    findTodayByStartDate();
  }, [findTodayByStartDate, firebaseData]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const ref = {
    date: selectedDate.getDate(),
    month: months[selectedDate.getMonth()],
    year: selectedDate.getFullYear(),
  };
  const getBookText = (dataRef, text) => {
    let ref = dataRef.split(" ");
    let book = bookList.find((element) => element.book_code === ref[0]);
    let verse = text.split(":")[1];

    if (verse) {
      verse = ":" + verse;
    } else {
      verse = "";
    }
    return book ? book.short + " " + ref[1] + verse : "";
  };

  //Open reference
  const openChapter = (event) => {
    let element = event.currentTarget;
    let ref = element.getAttribute("data-ref").split(" ");
    let book = bookList.find((element) => element.book_code === ref[0]);
    if (book) {
      setValue1("versesSelected", []);
      setValue1("bookCode", ref[0]);
      setValue1("chapter", parseInt(ref[1]));
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      aria-haspopup="true"
      variant="contained"
      onClick={onClick}
      ref={ref}
      sx={{
        [`&.MuiButton-root`]: {
          textTransform: "capitalize",
          backgroundColor: "#fff",
        },
        color: BLACK,
      }}
    >
      {value}
    </Button>
  ));
  const API = useMemo(
    () => axios.create({ baseURL: process.env.REACT_APP_BIBLE_PLANS_URL }),
    []
  );
  useEffect(() => {
    if (readingPlans) {
      setPlans(readingPlans);
      setPlan(readingPlans[0]);
    }
  }, [readingPlans]);

  useEffect(() => {
    if (plan) {
      setLoading(true);
      API.get(plan.value).then(function (response) {
        setPlanData(response.data);
        setLoading(false);
      });
    }
  }, [API, plan]);

  useEffect(() => {
    if (planData.length !== 0) {
      if (todayDay !== -1 && firebaseData && isPlanStarted) {
        const daysPlan = planData[todayDay];
        const donePlan = firebaseData?.plan[todayDay]?.done || [];
        setCompletedReading(donePlan);
        setReadingList(daysPlan ? daysPlan.reading : []);
      } else {
        const month = String(selectedDate.getMonth() + 1);
        const day = String(selectedDate.getDate());
        const readingDay = month.padStart(2, "0") + "-" + day.padStart(2, "0");
        const daysPlan = planData.find(({ date }) => date === readingDay);
        setReadingList(daysPlan ? daysPlan.reading : []);
      }
    }
  }, [firebaseData, planData, selectedDate, todayDay, isPlanStarted]);

  const createPlan = () => {
    let totalCompletedReadings = 0;
    let planData = [];
    axios
      .get(`${process.env.REACT_APP_BIBLE_PLANS_URL}/mcheyne.json`)
      .then((res) => {
        planData = res.data.map((el, index) => {
          totalCompletedReadings += el.reading.length;
          return {
            day: index + 1,
            date: moment().add(index, "days").format("DD-MM-YYYY"),
            done: [],
          };
        });
        let readingPlan = {
          planName: plan.label,
          startedDate: moment().toDate(),
          expectedCompletedDate: moment()
            .add("364", "Days")
            .format("DD-MM-YYYY"),
          completedDays: 0,
          completedReadings: 0,
          totalReadings: totalCompletedReadings,
          totalDays: 365,
          plan: planData,
        };
        setReadingPlanDetail(readingPlan);
        setCreatePlanModel(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function deleteFirebasePlan() {
    setAnchorEl(null);
    return firebase
      .ref("users/" + uid + "/readingPlan")
      .remove()
      .then(function () {})
      .catch(function (error) {
        console.error("Error deleting reading plan:", error);
      });
  }

  function createFirebasePlan(readingPlan) {
    let planData = { ...readingPlan };
    if (!planData.planLabel) planData.planLabel = plan.label;
    planData.startedDate = moment(readingPlan.startedDate, "DD-MM-YYYY").format(
      "DD-MM-YYYY"
    );
    planData.expectedCompletedDate = moment(
      readingPlan.startedDate,
      "DD-MM-YYYY"
    )
      .add("364", "Days")
      .format("DD-MM-YYYY");
    return firebase
      .ref("users/" + uid + "/readingPlan")
      .set(planData, function (error) {
        if (!error) {
          if (createPlanModel || editPlanModel) {
            setTodayDay(-1);
            setCreatePlanModel(false);
            setEditPlanModel(false);
          }
        }
      });
  }

  const createModelClose = () => {
    setCreatePlanModel(false);
    setEditPlanModel(false);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  return (
    <Box sx={{ width: "100%", marginTop: { lg: 10.125, sm: 8.75, xs: 0.625 } }}>
      <Box
        sx={{
          paddingLeft: { lg: 4.375, xs: 1.875 },
          paddingBottom: 1.5,
          marginBottom: 2.5,
          borderBottom: "1px solid #f1ecec",
          display: "flex",
          width: "100%",
          boxShadow: { lg: 0, xs: 1 },
        }}
      >
        <Box flexGrow={1}>
          {mobileView ? null : (
            <Typography
              variant="h6"
              sx={{
                [theme.breakpoints.down("1450")]: {
                  display: locale === "ml" ? "none" : "block",
                },
              }}
            >
              {" "}
              {t("readingPlansText")}
            </Typography>
          )}
        </Box>
        <Box flexGrow={1}>
          {plan ? (
            <Box sx={{ display: { lg: "flex", xs: "flex" } }}>
              <StyledSelect
                defaultValue={plan}
                onChange={(data) => setPlan(data)}
                options={plans}
                isSearchable={false}
              />
              {firebaseData && isPlanStarted && (
                <CustomSelect
                  placeholder={t("day")}
                  onChange={(el) => {
                    setTodayDay(el.value);
                    setSelectedDate(
                      moment(firebaseData.startedDate, "DD/MM/YYYY")
                        .add(el.value, "days")
                        .toDate()
                    );
                  }}
                  options={Array.from(Array(differenceDayToday).keys())
                    .map((el) => {
                      return {
                        label: moment(firebaseData?.startedDate, "DD-MM-YYYY")
                          .add(el, "Days")
                          .format("DD-MM-YYYY"),
                        value: el,
                      };
                    })
                    .filter(
                      (el) =>
                        firebaseData.plan[el.value]?.done?.length !==
                        planData[el.value]?.reading?.length
                    )}
                />
              )}
              {mobileView && !firebaseData ? (
                <StyledDatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  withPortal
                  todayButton="Today"
                  dateFormat="dd MMM"
                  showMonthDropdown
                  minDate={new Date(new Date().getFullYear(), 0, 1)}
                  maxDate={new Date(new Date().getFullYear(), 11, 31)}
                  customInput={<CustomInput />}
                />
              ) : null}
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {firebaseData && isPlanStarted && (
            <PlanInfo firebaseData={firebaseData} />
          )}
          {firebaseData && (
            <>
              <Tooltip title={t("deletePlan")}>
                <DeleteIcon
                  onClick={handlePopoverClick}
                  sx={{
                    color: BLACK,
                    cursor: "pointer",
                    marginTop: { xs: "5px", md: "unset" },
                  }}
                />
              </Tooltip>
              <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">
                    {t("deletePlanMessage")}
                  </Typography>
                  <Button
                    onClick={deleteFirebasePlan}
                    sx={{ mt: 1 }}
                    color="error"
                  >
                    {t("delete")}
                  </Button>
                  <Button
                    onClick={handlePopoverClose}
                    sx={{ mt: 1, color: BLACK }}
                  >
                    {t("commonClose")}
                  </Button>
                </Box>
              </Popover>
            </>
          )}
          <Help
            iconStyle={{
              color: BLACK,
              marginTop: { lg: "2px", md: "-2px", xs: "5px" },
              fontSize: 21,
            }}
            url={"readingPlans"}
          />
          <Close
            sx={{
              marginRight: { lg: "10px", md: "15px" },
              marginTop: { xs: "6px", md: "2px" },
            }}
          />
        </Box>
      </Box>
      {userDetails.uid && firebaseData === undefined ? (
        <Heading>{t("loadingMessage")}</Heading>
      ) : (
        <Box
          sx={{
            top: { lg: 134, sm: 123, xs: 57 },
            bottom: 0,
            paddingTop: { md: 3.75, sm: 0, xs: 0 },
            paddingRight: { xs: 0 },
            paddingBottom: { xs: 0.625 },
            paddingLeft: { xs: 0.625 },
            overflow: "auto",
            position: "absolute",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0,0,0,.4) #eeeeee95",
            width: "100%",
            "-webkit-scrollbar": {
              width: "0.45em",
            },
            "-webkit-scrollbar-track": {
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.4)",
              outline: "1px solid slategrey",
            },
          }}
        >
          {(firebaseData && isPlanStarted) || mobileView ? null : (
            <StyledCalendar
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate}
            />
          )}
          <Box
            sx={{
              backgroundColor: firebaseData ? LIGHTGREY : WHITE,
              padding: "10px 5px",
            }}
          >
            {firebaseData && isPlanStarted && (
              <Box
                sx={{
                  display: "flex",
                  width: "98%",
                  overflow: "auto",
                  flexWrap: "nowrap",
                  margin: "0px auto",
                  gap: "10px",
                  border: "2px solid " + LIGHTGREY,
                  borderRadius: "10px",
                  padding: "5px 10px",
                  background: WHITE,
                  boxShadow: "1px 1px 1px 1px " + WHITE,
                }}
              >
                {firebaseData.plan.map((el, i) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      ref={(el) => (todayCarouselRef.current[i] = el)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: todayDay === i ? "#ff3d4d" : "#F0F0F0",
                          color: todayDay === i ? "#FFF" : "#000",
                          minWidth: "60px",
                          maxWidth: "60px",
                          height: "60px",
                          borderRadius: "30px",
                          margin: "30px 0px",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTodayDay(i);
                          setSelectedDate(
                            moment(firebaseData.startedDate, "DD/MM/YYYY")
                              .add(i, "days")
                              .toDate()
                          );
                        }}
                      >
                        {firebaseData.plan[i]?.done?.length ===
                        planData[i]?.reading?.length ? (
                          <DoneIcon />
                        ) : (
                          i + 1
                        )}
                      </Box>
                      {moment(firebaseData.startedDate, "DD/MM/YYYY")
                        .add(i, "days")
                        .format("MMM DD")}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box sx={{ width: "100%", marginTop: { md: 7.5, sm: 0 } }}>
            {!firebaseData && userDetails.uid && (
              <Box
                sx={{
                  marginY: "20px",
                  marginX: "auto",
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  [theme.breakpoints.down("md")]: {
                    width: "90%",
                  },
                }}
              >
                <Box
                  sx={{
                    borderLeft: "20px solid #860000",
                    paddingLeft: "10px",
                  }}
                >
                  {t("noActivePlan")}
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    margin: theme.spacing(1.5),
                    backgroundColor: "#fff",
                    boxShadow: "1px 1px 1px 1px " + GREY,
                    height: "42px",
                    "&:hover": {
                      textDecoration: "none",
                      backgroundColor: BLACK + "0a",
                    },
                    [theme.breakpoints.down("md")]: {
                      margin: "0px",
                      width: "60%",
                      marginTop: "0px",
                      marginBottom: "2px",
                    },
                    [theme.breakpoints.only("sm")]: {
                      margin: theme.spacing(1.5),
                    },
                    color: BLACK,
                    fontSize: locale === "ml" || "kn" ? 10 : "unset",
                  }}
                  onClick={() => {
                    createPlan();
                  }}
                >
                  {t("createPlan")}
                </Button>
              </Box>
            )}
            {firebaseData && !isPlanStarted && (
              <Box
                sx={{
                  marginY: "20px",
                  marginX: "auto",
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  [theme.breakpoints.down("md")]: {
                    width: "90%",
                  },
                }}
              >
                <Box
                  sx={{
                    borderLeft: "20px solid #860000",
                    paddingLeft: "10px",
                  }}
                >
                  {t("futurePlan")}{" "}
                  {moment(firebaseData.startedDate, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  )}
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    margin: theme.spacing(1.5),
                    backgroundColor: "#fff",
                    boxShadow: "1px 1px 1px 1px " + GREY,
                    height: "42px",
                    "&:hover": {
                      textDecoration: "none",
                      backgroundColor: BLACK + "0a",
                    },
                    [theme.breakpoints.down("md")]: {
                      margin: "0px",
                      width: "60%",
                      marginTop: "0px",
                      marginBottom: "2px",
                    },
                    [theme.breakpoints.only("sm")]: {
                      margin: theme.spacing(1.5),
                    },
                    color: BLACK,
                    fontSize: locale === "ml" || "kn" ? 10 : "unset",
                  }}
                  onClick={() => {
                    setReadingPlanDetail({
                      ...firebaseData,
                      startedDate: moment(
                        firebaseData.startedDate,
                        "DD-MM-YYYY"
                      ).toDate(),
                    });
                    setEditPlanModel(true);
                  }}
                >
                  {t("modifyPlan")}
                </Button>
              </Box>
            )}
            <Box
              sx={{
                paddingBottom: 1.25,
                paddingLeft: 1.875,
                borderBottom: "1px solid #f1ecec",
                display: { md: "flex", xs: "none" },
                width: "100%",
                height: "2.75em",
              }}
            >
              <Box sx={{ display: { lg: "block", xs: "flex" } }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  {t("readingPlanBibleRef", { ref })}
                </Typography>
              </Box>
            </Box>
            <>
              {loading ? (
                <Heading>{t("loadingMessage")}</Heading>
              ) : readingList.length !== 0 ? (
                <List
                  component="nav"
                  sx={{
                    bottom: 50,
                    top: 0,
                  }}
                >
                  {readingList.map((reading, i) => {
                    const bookText = getBookText(reading.ref, reading.text);
                    return bookText ? (
                      <ListItemButton
                        key={i}
                        sx={{ borderBottom: "1px solid lightgray" }}
                      >
                        <ListItemText
                          primary={bookText}
                          data-ref={reading.ref}
                          onClick={(e) => openChapter(e)}
                        />
                        {firebaseData && isPlanStarted && (
                          <Checkbox
                            checked={completedReading.includes(i)}
                            onChange={() => {
                              let currentPlan = { ...firebaseData };
                              let isDayComplete =
                                readingList.length === completedReading.length;
                              deleteFirebasePlan();
                              if (completedReading.includes(i)) {
                                currentPlan.plan[todayDay].done =
                                  currentPlan.plan[todayDay].done.filter(
                                    (el) => el !== i
                                  );
                                currentPlan.completedReadings -= 1;
                                if (isDayComplete) {
                                  currentPlan.completedDays -= 1;
                                }
                                createFirebasePlan(currentPlan);
                              } else {
                                currentPlan.plan[todayDay].done = [
                                  ...completedReading,
                                  i,
                                ];
                                isDayComplete =
                                  readingList.length ===
                                  currentPlan.plan[todayDay].done.length;
                                if (isDayComplete) {
                                  currentPlan.completedDays += 1;
                                }
                                currentPlan.completedReadings += 1;
                                createFirebasePlan(currentPlan);
                              }
                            }}
                          />
                        )}
                      </ListItemButton>
                    ) : (
                      <BigTooltip
                        key={i}
                        title={t("readingPlanBookNotAvailableToolTip")}
                        arrow
                      >
                        <span>
                          <ListItem
                            key={i}
                            sx={{ borderBottom: "1px solid lightgray" }}
                          >
                            <Button
                              aria-label="Book not available"
                              sx={{
                                textTransform: "none",
                                marginLeft: -1,
                              }}
                              disabled
                              endIcon={<ErrorOutlineIcon />}
                            >
                              {reading.text}
                            </Button>
                            {mobileView ? (
                              <Typography
                                sx={{
                                  color: GREY,
                                  fontSize: "0.9rem",
                                  float: "right",
                                }}
                              >
                                {t("readingPlanBookNotAvailableMob")}
                              </Typography>
                            ) : null}
                          </ListItem>
                        </span>
                      </BigTooltip>
                    );
                  })}
                </List>
              ) : (
                <Typography sx={{ margin: 2.25 }}>
                  {t("noReadingPlanAvailable")}
                </Typography>
              )}
            </>
          </Box>
        </Box>
      )}
      <Dialog
        open={createPlanModel}
        onClose={createModelClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={false}
        maxWidth="md"
      >
        <CreatePlan
          readingPlan={readingPlanDetail}
          handleClose={createModelClose}
          createFirebasePlan={createFirebasePlan}
        />
      </Dialog>
      <Dialog
        open={editPlanModel}
        onClose={() => {
          createModelClose();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={false}
        maxWidth="md"
      >
        <CreatePlan
          readingPlan={readingPlanDetail}
          handleClose={() => {
            createModelClose();
          }}
          createFirebasePlan={(data) => {
            deleteFirebasePlan();
            createFirebasePlan(data);
          }}
        />
      </Dialog>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetails: state.local.userDetails,
    locale: state.local.locale,
  };
};

export default connect(mapStateToProps)(ReadingPlan);
