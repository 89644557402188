import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import BigTooltip from "../common/BigTooltip";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";

const LinkButton = styled(Link)(({ theme }) => ({
  display: "block",
  margin: "10px",
  [theme.breakpoints.only("xl")]: {
    display: "unset",
  },
  [theme.breakpoints.only("xl")]: {
    margin: "8px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0px",
  },
  "&:hover": {
    textDecoration: "none",
  },
}));

function ImageCard({ icon, type, onClick, locale }) {
  const theme = useTheme();
  const isLarge = useMediaQuery("(min-width:1550px)");
  const { t } = useTranslation();
  const heading = {
    Read: t("landingReadTitle"),
    View: t("landingViewTitle"),
    Watch: t("landingWatchTitle"),
    Listen: t("landingListenTitle"),
  };
  const caption = {
    Read: t("landingReadCaption"),
    View: t("landingViewCaption"),
    Watch: t("landingWatchCaption"),
    Listen: t("landingListenCaption"),
  };
  return (
    <Grid item sm={3} xs={6} sx={{ p: { sm: 0, xs: 0.5 } }}>
      <LinkButton
        to={{
          pathname:
            type === "Read"
              ? "/read"
              : type === "Listen"
              ? "/audiobible"
              : type === "View"
              ? "/infographics"
              : "/videos",
        }}
      >
        <BigTooltip title={caption[type]}>
          <Paper
            sx={{
              "&.MuiPaper-root": {
                margin: "0 auto",
                textAlign: "center",
                padding: "15px 30px",
                display: "flex",
                width: "75%",
                "&:hover": {
                  backgroundColor: "#d2d5f7",
                },
                [theme.breakpoints.only("lg")]: {
                  margin: "0 5px",
                  padding: "15px 20px",
                },
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  width: "80%",
                  margin: "auto",
                },
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  margin: "0 auto",
                },
              },
            }}
            elevation={3}
            onClick={onClick}
          >
            <Box
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "8px",
                  fontSize:
                    locale === "en" || locale === "hi" ? "1.8rem" : "1.4rem",
                  [theme.breakpoints.down(850)]: {
                    fontSize:
                      locale === "en" || locale === "hi" ? "1.8rem" : "1rem",
                    marginBottom: 0,
                  },
                }}
              >
                {heading[type]}
              </Typography>
              <Box
                sx={{
                  fontSize:
                    locale === "en" || locale === "hi" ? "20px" : "14px",
                  height: isLarge ? "85px" : "115px",
                  display: "flex",
                  [theme.breakpoints.down("lg")]: {
                    display: "none",
                  },
                }}
              >
                {caption[type]}
              </Box>
            </Box>
          </Paper>
        </BigTooltip>
      </LinkButton>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.local.locale,
  };
};

export default connect(mapStateToProps)(ImageCard);
