import React, { useEffect, useState, useRef } from "react";
import TopBar from "../read/TopBar";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Typography,
  useMediaQuery,
  Select,
  Snackbar,
} from "@mui/material/";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { connect } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import * as actions from "../../store/actions";
import ReactSelect from "react-select";
import { capitalize, getVersions } from "../common/utility";
import { API } from "../../store/api";
import { useTranslation } from "react-i18next";
import { BLACK, GREY } from "../../store/colorCode";
import { languageJson } from "../../store/languageData";
import BookCombo from "../common/BookCombo";
import { useSearchParams } from "react-router-dom";
import Help from "../common/Help";

const CustomSelect = styled(ReactSelect)(({ theme }) => ({
  width: "400px",
  [theme.breakpoints.down("md")]: {
    width: "280px",
    margin: "10px 0px",
  },
}));

const ArrowBackward = styled(ArrowBackIosIcon)(
  ({ theme, padding, audiobottom }) => ({
    position: "fixed",
    top: "45%",
    marginLeft: "20px",
    cursor: "pointer",
    boxShadow: "rgb(0 0 0 / 50%) 0px 3px 10px 0px",
    borderRadius: "50%",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px white",
    padding: "7px",
    [theme.breakpoints.up("md")]: {
      left: padding > 5 ? padding / 2 : 2.5,
    },
    [theme.breakpoints.down("md")]: {
      left: "10px",
      top: "unset",
      bottom: "2%",
      marginLeft: "0px",
    },
  })
);

const ArrowForward = styled(ArrowForwardIosIcon)(
  ({ theme, padding, audiobottom }) => ({
    position: "fixed",
    top: "45%",
    marginRight: "20px",
    cursor: "pointer",
    boxShadow: "rgb(0 0 0 / 50%) 0px 3px 10px 0px",
    borderRadius: "50%",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px white",
    padding: "7px",
    [theme.breakpoints.up("md")]: {
      right: padding > 5 ? padding / 2 : 2.5,
    },
    [theme.breakpoints.down("md")]: {
      right: "10px",
      top: "unset",
      bottom: "2%",
      marginRight: "0px",
    },
  })
);

function Comparison(props) {
  const { t } = useTranslation();
  const [verses, setVerses] = useState([]);
  const [selectedVerseIndex, setSelectedVerseIndex] = useState(-1);
  const [sourceId, setSourceId] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [verseList, setVerseList] = useState([]);
  const [prevChapter, setPrevChapter] = useState(null);
  const [nextChapter, setNextChapter] = useState(null);
  const prevClicked = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownError, setDropdownError] = useState(false);
  const [languages, setLanguages] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [bookDisplay, setBookDisplay] = React.useState("");
  const [searchParams] = useSearchParams();

  const readLanguage = searchParams.get("language");
  let readVerse = searchParams.get("verse");
  const readBook = searchParams.get("bookCode");
  const readChapter = searchParams.get("chapter");

  const {
    setVersions,
    setValue,
    panel1,
    versions,
    setVersionBooks,
    setMainValue,
    versionBooks,
  } = props;
  const { bookCode, chapter } = panel1;

  const bookList = versionBooks[selectedLanguage[0]?.value];

  useEffect(() => {
    let _version = localStorage.getItem("version");
    let _bookCode = localStorage.getItem("bookCode");
    let _chapter = localStorage.getItem("chapter");
    let _verseData = localStorage.getItem("verseData");
    // if versions not set fetch bible versions
    if (versions.length === 0) {
      getVersions(
        setVersions,
        setValue,
        setVersionBooks,
        setMainValue,
        _version,
        _bookCode,
        _chapter,
        _verseData
      );
      setSelectedVerseIndex(0);
    }
  }, [setVersions, setValue, setVersionBooks, setMainValue, versions.length]);

  React.useEffect(() => {
    if (bookList) {
      let book = bookList.find((element) => element.book_code === bookCode);
      if (book) {
        setBookDisplay(book.short);
      }
    }
  }, [bookList, bookCode, setBookDisplay]);

  useEffect(() => {
    if (Object.keys(versionBooks).length > 0) {
      let languageList = [];
      Object.keys(versionBooks).forEach((langVersion) => {
        let findLanguage = languageJson.find(
          (el) => langVersion === el.langCode
        );
        if (findLanguage) {
          languageList.push({
            value: findLanguage.langCode,
            label: capitalize(findLanguage.language),
          });
          const sourceCode = versions.find(
            (e) => e?.languageVersions[0]?.language?.code === "eng"
          )?.languageVersions[0]?.sourceId;
          setSourceId(sourceCode);
          if (findLanguage.langCode === "eng") {
            setSelectedLanguage([
              {
                value: findLanguage.langCode,
                label: capitalize(findLanguage.language),
              },
            ]);
          }
        }
      });
      setLanguages(languageList.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [versionBooks, versions]);

  useEffect(() => {
    // fetch prev and next chapter details
    const prevNextChapter = () => {
      if (sourceId && bookCode && chapter) {
        let url = `bibles/${sourceId}/books/${bookCode}/chapter/${chapter}`;
        API.get(url)
          .then((res) => {
            setPrevChapter(res.data.previous);
            setNextChapter(res.data.next);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    // fetch verse list for current chapter
    const fetchVerseList = () => {
      let url = `bibles/${sourceId}/books/${bookCode}/chapters/${chapter}/verses`;
      API.get(url)
        .then((res) => {
          setVerses(res.data.map((a) => a.verse.verseId));
          setSelectedVerseIndex(() => {
            if (prevClicked.current) {
              prevClicked.current = false;
              return res.data.length - 1;
            } else if (
              readVerse &&
              readBook === bookCode &&
              parseInt(chapter) === parseInt(readChapter)
            ) {
              return readVerse - 1;
            } else {
              return 0;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (sourceId && bookCode && chapter) {
      prevNextChapter();
      fetchVerseList();
    }
  }, [sourceId, bookCode, chapter, readVerse, readBook, readChapter]);

  useEffect(() => {
    if (
      bookCode &&
      verses.length > 0 &&
      selectedVerseIndex !== -1 &&
      versions.length > 0 &&
      selectedLanguage &&
      selectedLanguage.length > 0
    ) {
      const fetchVerse = async () => {
        setIsLoading(true);
        setVerseList([]);
        let tmpVerseList = {};
        let promises = [];

        let findSelectedLanguage = selectedLanguage
          .map((language) => {
            return versions.findIndex(
              (el) => el.language.toLowerCase() === language.label.toLowerCase()
            );
          })
          .filter((index) => index !== -1);

        if (findSelectedLanguage.length !== 0) {
          for (let langIndex of findSelectedLanguage) {
            let languageKey = versions[langIndex].language;
            if (!tmpVerseList[languageKey]) {
              tmpVerseList[languageKey] = [];
            }

            for (let languageVersion of versions[langIndex].languageVersions) {
              let promise = API.get(
                `bibles/${languageVersion.sourceId}/verses/${bookCode}.${verses[selectedVerseIndex]}`
              )
                .then((res) => {
                  if (res?.data?.verseContent?.text) {
                    tmpVerseList[languageKey].push({
                      code: languageVersion?.version?.code,
                      verse: res?.data?.verseContent?.text,
                      langCode: languageVersion.language.code,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

              promises.push(promise);
            }
          }
        }

        try {
          await Promise.all(promises);
          setVerseList(
            Object.keys(tmpVerseList).map((key) => ({
              [key]: tmpVerseList[key],
            }))
          );
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching verses:", error);
        }
      };
      fetchVerse();
    } else {
      setVerseList([]);
    }
  }, [bookCode, versions, selectedVerseIndex, selectedLanguage, verses]);

  const prevVerse = () => {
    if (selectedVerseIndex !== 0) {
      setSelectedVerseIndex((prevValue) => prevValue - 1);
    } else {
      prevClicked.current = true;
      setVerses([]);
      if (prevChapter?.bibleBookCode) {
        setValue("chapter", prevChapter.chapterId);
        setValue("bookCode", prevChapter.bibleBookCode);
        setValue("sourceId", prevChapter.sourceId);
      }
    }
  };

  const nextVerse = () => {
    if (selectedVerseIndex + 1 < verses.length) {
      setSelectedVerseIndex((prevValue) => prevValue + 1);
    } else {
      setVerses([]);
      if (nextChapter?.bibleBookCode) {
        setValue("chapter", nextChapter.chapterId);
        setValue("bookCode", nextChapter.bibleBookCode);
        setValue("sourceId", nextChapter.sourceId);
      }
    }
  };

  const verseSelect = () =>
    verses && selectedVerseIndex !== -1 ? (
      <Select
        value={selectedVerseIndex}
        sx={{
          "& .MuiSelect-select": {
            "&:focus": {
              backgroundColor: "transparent",
            },
            padding: "10px 20px",
          },
        }}
      >
        {verses?.map((verseDetail, y) => (
          <MenuItem
            key={verseDetail}
            value={y}
            onClick={() => setSelectedVerseIndex(y)}
          >
            <Typography sx={{ width: "100%" }}>
              {verseDetail?.split(".")[1]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    ) : (
      ""
    );

  useEffect(() => {
    if (readLanguage && readLanguage.length > 0) {
      const selLanguage = readLanguage
        .split(",")
        .map((langValue) => {
          return languages.find((el) => el.value === langValue.trim());
        })
        .filter(Boolean);
      setSelectedLanguage(selLanguage);
    }
  }, [languages, readLanguage, setSelectedLanguage]);

  const handleSnackBarClick = () => {
    setDropdownError(true);
  };

  const handleSnackBarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDropdownError(false);
  };

  const getPrevious = () => {
    return <ArrowBackward fontSize="large" onClick={() => prevVerse()} />;
  };
  const getNext = () => {
    return <ArrowForward fontSize="large" onClick={() => nextVerse()} />;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "auto",
          position: "fixed",
          width: "100%",
          zIndex: "9",
          background: "white",
          borderBottom: "1px solid #d4d4d4",
        }}
      >
        <TopBar />
        <Box
          sx={{
            width: "100%",
            marginTop: "82px",
            display: "flex",
            height: "100%",
            marginBottom: "10px",
            justifyContent: "space-around",
            [theme.breakpoints.down("md")]: {
              marginTop: "60px",
              marginBottom: "5px",
              flexDirection: "column-reverse",
            },
            [theme.breakpoints.only("xs")]: {
              marginTop: "65px",
              marginBottom: 0,
            },
          }}
        >
          <Box
            sx={{
              marginLeft: isMobile ? "-15px" : "-100px",
              display: "flex",
              minWidth: "325px",
              alignItems: "center",
              justifyContent: "space-around",
              [theme.breakpoints.only("sm")]: {
                paddingTop: "5px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                gap: "5px",
                alignItems: "center",
              }}
            >
              {languages && languages?.length !== 0 && (
                <CustomSelect
                  value={selectedLanguage}
                  isMulti
                  onChange={(data) => {
                    if (data.length <= 4) {
                      const sourceCode = versions.find(
                        (e) =>
                          e?.languageVersions[0]?.language?.code ===
                          data[0]?.value
                      )?.languageVersions[0]?.sourceId;
                      setSourceId(sourceCode);
                      setSelectedLanguage(data);
                    } else {
                      handleSnackBarClick();
                    }
                  }}
                  options={languages}
                />
              )}
              {isMobile ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {bookCode !== "" && bookCode !== undefined ? (
                    <BookCombo
                      bookCode={bookCode}
                      bookList={versionBooks[selectedLanguage[0]?.value]}
                      chapter={chapter}
                      setValue={setValue}
                      minimal={true}
                    />
                  ) : (
                    ""
                  )}
                  <FormControl variant="outlined" sx={{ minWidth: "30px" }}>
                    {verseSelect()}
                  </FormControl>
                </Box>
              ) : (
                <>
                  {bookCode !== "" && bookCode !== undefined ? (
                    <BookCombo
                      bookCode={bookCode}
                      bookList={versionBooks[selectedLanguage[0]?.value]}
                      chapter={chapter}
                      setValue={setValue}
                      minimal={true}
                    />
                  ) : (
                    ""
                  )}
                  <FormControl variant="outlined" sx={{ minWidth: "30px" }}>
                    {verseSelect()}
                  </FormControl>
                </>
              )}
            </Box>
          </Box>
          {isMobile ? <Divider /> : ""}
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                margin: "0 auto",
                fontSize: "1.7rem",
                [theme.breakpoints.down("md")]: {
                  margin: "0 10px",
                },
              }}
            >
              {t("comparisonTopBarBtn")}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 20,
              top: isMobile ? 70 : 90,
            }}
          >
            <Help
              iconStyle={{
                color: BLACK,
                fontSize: "21px",
              }}
              url={"compareTranslations"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: "170px",
          display: "flex",
          flexDirection: "column",
          paddingInline: "7%",
          overflowX: "hidden",
          overflowY: "auto",
          marginBottom: "20px",
          paddingBottom: "50px",
          [theme.breakpoints.down("md")]: {
            paddingTop: "280px",
          },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {t("loadingMessage")}
          </Box>
        ) : verseList.length > 0 ? (
          verseList.map((verse) => {
            const language = Object.keys(verse)[0];
            const verses = verse[language];

            return (
              <React.Fragment key={language}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "800",
                    margin: "10px 0px",
                  }}
                >
                  {capitalize(language)}
                </Typography>
                {verses.length > 0 ? (
                  verses.map((el) => (
                    <Box
                      key={el.code}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          margin: "10px 0px",
                        }}
                      >
                        {el.code}:{" "}
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: "18px",
                            fontWeight: "400",
                            paddingBlock: "10px",
                            wordSpacing: "4px",
                          }}
                        >
                          {el.verse}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          aria-controls="commentary-menu"
                          aria-haspopup="true"
                          onClick={() =>
                            (window.location.href = `/read?version=${
                              el.langCode
                            }-${
                              el.code
                            }&reference=${bookCode}.${chapter}&lastPath=compare&language=${selectedLanguage
                              .map((el) => el.value)
                              .join(",")}&verse=${selectedVerseIndex + 1}`)
                          }
                          variant="contained"
                          sx={{
                            "&.MuiButton-root": {
                              margin: "5px 0px",
                              fontSize: "1rem",
                              textTransform: "capitalize",
                              backgroundColor: "#fff",
                              border: "1px solid #fff",
                              boxShadow: "1px 1px 1px 1px " + GREY,
                              color: BLACK,
                            },
                          }}
                        >
                          {t("readTopBarBtnMob")}{" "}
                          {(versionBooks[el.langCode] &&
                            versionBooks[el.langCode].find(
                              (book) => book.book_code === bookCode
                            )?.short) ||
                            bookDisplay}{" "}
                          {chapter}{" "}{el.code}
                        </Button>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography>{t("noVerseAvailable")}</Typography>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </Box>
      {(prevChapter?.bibleBookCode || selectedVerseIndex !== 0) &&
        getPrevious()}
      {(nextChapter?.bibleBookCode ||
        selectedVerseIndex !== verses.length - 1) &&
        getNext()}
      <Snackbar
        open={dropdownError}
        autoHideDuration={1000}
        onClose={handleSnackBarClose}
        message={t("comparisonDropDownError")}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    versions: state.local.versions,
    versionBooks: state.local.versionBooks,
    panel1: state.local.panel1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (name, value) =>
      dispatch({ type: actions.SETVALUE1, name: name, value: value }),
    setVersions: (value) =>
      dispatch({ type: actions.SETVERSIONS, value: value }),
    setVersionBooks: (name, value) =>
      dispatch({ type: actions.ADDVERSIONBOOKS, name: name, value: value }),
    setMainValue: (name, value) =>
      dispatch({ type: actions.SETVALUE, name: name, value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comparison);
