import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BLACK } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const BlogSection = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const blogsURL = process.env.REACT_APP_BLOGS_URL;
  const imageURL = process.env.REACT_APP_BLOGS_IMAGE_URL;   

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${blogsURL}api/getPosts`);
        const result = await response.json();
        setData(result);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [blogsURL]);

  return data.length === 0 ? (
    ""
  ) : (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        paddingBottom: "50px",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Box
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: { xs: "30px", sm: "2.5rem" },
            paddingBottom: "10px",
          }}
        >
          {t("blogTitle")}
        </Box>
        <h5>{t("blogSubHeading")}</h5>
      </Box>
      <Box
        justifyContent="center"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          fontSize: "large",
        }}
      >
        {data.map((post) => (
          <Link
            key={post.slug}
            to={`${blogsURL}posts/${post.slug}`}
            target="_blank"
            style={{ textDecoration: "none", color: BLACK }}
          >
            <Card sx={{ maxWidth: 345, maxHeight: 300 }}>
              <CardMedia
                sx={{ height: 180 }}
                image={imageURL + post.frontMatter.image}
                title={post.frontMatter.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {post.frontMatter.title}
                </Typography>
                <Typography variant="body2" sx={{ textOverflow: "ellipsis" }}>
                  {post.content.substring(0, 100)}...
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default BlogSection;
