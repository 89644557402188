import React, { useState, useEffect, useRef } from "react";
import ModalVideo from "react-modal-video";
import Select from "react-select";
import { capitalize } from "../common/utility";
import { useTranslation } from "react-i18next";
import { BLACK, GREY } from "../../store/colorCode";
import Help from "../common/Help";
import { styled } from "@mui/system";
import {
  TextField,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  AppBar,
  Button,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { API } from "../../store/api";
import TopBar from "../read/TopBar";
import CloseIcon from "@mui/icons-material/Close";

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  maxWidth: 250,
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
}));

const SearchBox = styled(TextField)(({ theme }) => ({
  marginLeft: "12px",
  width: "100%",
  maxWidth: 280,
  [theme.breakpoints.down("md")]: {
    width: "45%",
    marginLeft: "8px",
    marginRight: "4px",
  },
  "& .MuiInputBase-root": {
    height: "38px",
  },
}));

const FixedContainer = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #f1ecec",
  display: "flex",
  width: "100%",
  padding: "10px 16px",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  position: "fixed",
  top: 64,
  zIndex: 1000,
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "8px",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
}));

const CenterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "8px",
  },
}));

const RightContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexShrink: 0,
}));

const StyledButton = styled(Button)(() => ({
  width: "100%",
  marginBottom: "8px",
  textTransform: "capitalize",
  color: BLACK,
  border: "1px solid " + GREY,
  boxShadow: "1px 1px 1px 1px " + GREY,
  "&:hover": {
    backgroundColor: BLACK + "0a",
  },
}));

const VideosPage = () => {
  const [video, setVideo] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [videos, setVideos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const scrollRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [language]);

  useEffect(() => {
    API.get("videos")
      .then((response) => {
        setVideo(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getVideoData = (url) => {
    const vimeo = "https://vimeo.com/";
    const youtu = "https://youtu.be/";
    const vimeoUrl = "https://vumbnail.com/";
    const youtubeUrl = "https://img.youtube.com/vi/";
    const source = url.includes("vimeo") ? "vimeo" : "youtube";
    const id = source === "vimeo" ? url.split(vimeo)[1] : url.split(youtu)[1];
    const imageUrl =
      source === "vimeo" ? vimeoUrl + id + ".jpg" : youtubeUrl + id + "/0.jpg";
    return { source, id, imageUrl };
  };

  const handleVideoClick = (source, id) => {
    setVidLink(source);
    setVideoId(id);
    setIsOpen(true);
  };

  useEffect(() => {
    if (video.length > 0) {
      const languageCount = {};
      video.forEach((item) => {
        const langCode = item?.language?.code;
        if (langCode) {
          languageCount[langCode] = languageCount[langCode] || 0;

          Object.values(item.books)
            .flat()
            .forEach((book) => {
              languageCount[langCode]++;
            });
        }
      });

      const languageList = Object.keys(languageCount)
        .map((code) => {
          const langName = capitalize(
            video.find((item) => item.language.code === code)?.language?.name ||
              code
          );
          return {
            value: code,
            label: `${langName} (${languageCount[code]})`,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
      setLanguages(languageList);

      const defaultLanguage = languageList.find((l) => l.value === "en");
      setLanguage(defaultLanguage || languageList[0]);
    }
  }, [t, video]);

  useEffect(() => {
    if (language) {
      const lang = video.find((obj) => obj?.language?.code === language?.value);
      if (lang) {
        const allVideosData = Object.values(lang.books).flatMap((book) => book);
        setVideos(allVideosData);
        setFilteredVideos(allVideosData);
      } else {
        setVideos([]);
        setFilteredVideos([]);
      }
    }
  }, [video, language, t]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = videos.filter(
        (vid) =>
          vid.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          vid.theme.toLowerCase().includes(searchQuery.toLowerCase()) ||
          vid.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVideos(filtered);
    } else {
      setFilteredVideos(videos);
    }
  }, [searchQuery, videos]);

  function clearTextField() {
    setSearchQuery("");
  }

  return (
    <>
      <AppBar position="fixed">
        <TopBar />
      </AppBar>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          top: 125,
          [theme.breakpoints.down("sm")]: {
            top: 60,
          },
          bottom: 0,
        }}
      >
        {isSmallScreen ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid #f1ecec",
              position: "fixed",
              width: "100%",
              backgroundColor: theme.palette.background.paper,
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                width: "100%",
                paddingBottom: 1,
                borderBottom: "1px solid #f1ecec",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.125rem",
                  margin: "auto",
                }}
              >
                {t("videosText")}
              </Typography>
              <Help
                iconStyle={{
                  padding: "1px 1.5px 0",
                  color: BLACK,
                  marginTop: 0.275,
                  marginRight: 1,
                  fontSize: 23,
                }}
                url={"videos"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 1,
              }}
            >
              <StyledSelect
                value={language}
                onChange={(data) => setLanguage(data)}
                options={languages}
                sx={{ flexGrow: 1 }}
              />
              <SearchBox
                placeholder={t("searchPlaceHolderTopbarSmall")}
                variant="outlined"
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                InputProps={{
                  endAdornment: searchQuery && (
                    <InputAdornment>
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={clearTextField}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        ) : (
          <FixedContainer>
            <StyledSelect
              value={language}
              onChange={(data) => setLanguage(data)}
              options={languages}
            />
            <SearchBox
              placeholder={t("studyEnterSearchText")}
              variant="outlined"
              size="small"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              InputProps={{
                endAdornment: searchQuery && (
                  <InputAdornment>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={clearTextField}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <CenterContainer>
              <Typography variant="h4">{t("videosText")}</Typography>
            </CenterContainer>
            <RightContainer>
              <Help
                iconStyle={{
                  padding: "1px 1.5px 0",
                  color: BLACK,
                  marginTop: 0.275,
                  fontSize: 23,
                }}
                url={"videos"}
              />
            </RightContainer>
          </FixedContainer>
        )}
        <Box
          ref={scrollRef}
          sx={{
            height: "100%",
            overflowY: "scroll",
            maxHeight: { xs: "630px", sm: "unset" },
            position: "relative",
            width: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0,0,0,.4) #eeeeee95",
            "&::-webkit-scrollbar": {
              width: "0.45em",
            },
            "&::-webkit-scrollbar-track": {
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.4)",
              outline: "1px solid slategrey",
            },
            padding: "16px",
            boxSizing: "border-box",
            [theme.breakpoints.down("sm")]: {
              top: 110,
            },
          }}
        >
          {isLoading ? (
            <Typography variant="h6" sx={{ paddingLeft: 3.5 }}>
              {t("loadingMessage")}
            </Typography>
          ) : searchQuery && filteredVideos.length === 0 ? (
            <Typography variant="h6" sx={{ paddingLeft: 3.5 }}>
              {t("noSearchResultsMessage")}
            </Typography>
          ) : filteredVideos.length > 0 ? (
            <div>
              <ModalVideo
                channel={vidLink}
                isOpen={isOpen}
                videoId={videoId}
                onClose={() => setIsOpen(false)}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: isSmallScreen ? "8px" : "18px",
                  justifyContent: isSmallScreen ? "center" : "flex-start",
                }}
              >
                {filteredVideos.map((video, i) => {
                  const { source, id, imageUrl } = getVideoData(video.url);
                  return isSmallScreen ? (
                    <StyledButton
                      key={i}
                      onClick={() => handleVideoClick(source, id)}
                    >
                      {video.title}
                    </StyledButton>
                  ) : (
                    <Card
                      key={i}
                      onClick={() => handleVideoClick(source, id)}
                      sx={{
                        width: { sm: "30%", lg: "18%" },
                        margin: "0 0 2% 0",
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt="Video"
                          image={imageUrl}
                          title="Video"
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            title={video.title}
                            sx={{
                              paddingTop: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {video.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
              </Box>
            </div>
          ) : (
            <Typography variant="h6" sx={{ paddingLeft: 3.5 }}>
              {"studyNoVideosAvailable1"}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VideosPage;
