import { Tooltip, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from "@emotion/react";
const Help = ({ iconStyle, url }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const docLink = isMobile
    ? process.env.REACT_APP_MOBILE_DOCUMENT_URL
    : process.env.REACT_APP_DOCUMENT_URL;
  return (
    <>
      {docLink ? (
        <Tooltip title={t("landingHelpBtn")} style={{ float: "right" }}>
          <Link to={docLink + url} target="_blank">
            <HelpOutlineIcon fontSize="small" sx={iconStyle} />
          </Link>
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
};
export default Help;
