import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { API, CancelToken } from "../../store/api";
import {
  getReference,
  getVerse,
  getHeading,
  parseHeading,
} from "../common/utility";
import * as color from "../../store/colorCode";
import * as actions from "../../store/actions";
import TopBar from "../read/TopBar";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { styled } from "@mui/system";
import Version from "../common/Version";
import Help from "../common/Help";

const LoadingHeading = styled("h3")({
  paddingLeft: 20,
  position: "absolute",
  top: "176px",
});
const SearchMultiRef = (props) => {
  const { t } = useTranslation();
  const { panel1, versionSource, versionBooks, setValue1, searchText } = props;
  const { sourceId, bookCode, chapter, version, language, verseData } = panel1;
  const [versesArray, setVersesArray] = React.useState([]);
  const [bookDisplay, setBookDisplay] = React.useState("");
  const [bookDisplayArray, setBookDisplayArray] = React.useState([]);
  const [refArray, setRefArray] = React.useState([]);
  const [loadingText, setLoadingText] = React.useState(t("loadingMessage"));
  const [isLoading, setIsLoading] = React.useState(true);
  const footNotes = useRef({});
  const ftCount = useRef(0);
  const crossRefs = useRef({});
  const crCount = useRef(0);
  const footNote = true;
  const crossRef = true;
  const [error, setError] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1200));
  const navigate = useNavigate();
  var ref = {
    book: bookDisplay,
    chapter: chapter,
  };
  const cancelToken = React.useRef();
  const bookList = versionBooks[versionSource[sourceId]];
  function handleChapterRef(refObj) {
    setValue1("chapter", refObj?.chapter);
    setValue1("bookCode", refObj?.bookCode);
    setValue1("verseData", "");
    navigate("/read", { state: { from: "search" } });
  }

  const headingSx = {
    fontSize: "1.3em",
    display: "block",
    paddingTop: "12px",
    fontWeight: 700,
    textIndent: 0,
  };
  function filterVerse(verseData, verseNumber) {
    if (verseData) {
      if (isNaN(verseData)) {
        // verse range
        if (verseData?.match(/^[0-9-]*$/g)) {
          const [start, end] = verseData?.split("-");
          if (verseNumber < parseInt(start) || parseInt(end) < verseNumber) {
            return false;
          }
        }
        // multi verse
        if (verseData?.match(/^[0-9,]*$/g)) {
          if (!verseData?.split(",")?.includes?.(verseNumber)) {
            return false;
          }
        }
      } else if (verseNumber !== verseData) {
        return false;
      }
    }
    return true; //show verse
  }

  function getShowHeading(verseData, verseNumber) {
    const verseNo = parseInt(verseNumber);
    if (verseData !== "") {
      if (verseData?.match(/\d+-\d+/g)) {
        const [start, end] = verseData?.split("-");
        if (parseInt(start) === verseNo + 1) {
          return "show";
        }
        if (parseInt(end) === verseNo) {
          return "skip";
        }
      } else if (!isNaN(verseData)) {
        if (parseInt(verseData) === verseNo + 1) {
          return "show";
        }
        if (parseInt(verseData) === verseNo) {
          return "skip";
        }
      }
    }
    return "";
  }

  const handlePop = () => {
    return "";
  };

  function showText(item, verseData) {
    if (verseData === "" || verseData?.split("-")[0] === "1") {
      const heading = parseHeading(item, headingSx);
      if (heading !== "") {
        return heading;
      }
    }
    if (item?.verseNumber === "" || isNaN(item?.verseNumber)) {
      if (item?.verseNumber?.includes?.("-")) {
        const [start, end] = item?.verseNumber?.split("-");
        if (isNaN(start) || isNaN(end)) {
          return "";
        }
      } else {
        return "";
      }
    }
    const showHeading = getShowHeading(verseData, item.verseNumber);
    if (showHeading === "show") {
      return getHeading(item, headingSx);
    }
    if (!filterVerse(verseData, item.verseNumber)) {
      return "";
    }
    const verse = parseInt(item.verseNumber);

    const verseNumber = {
      fontWeight: 600,
      paddingLeft: "3px",
      bottom: verse === 1 ? "-2px" : "4px",
      position: "relative",
      fontSize: ".8em",
      color: color.MEDIUMGREY,
      display: "inline",
    };

    const verseNo = item.verseNumber;

    const poetrySx = {
      "&.poetry1": {
        paddingLeft: "1rem",
      },
      "&.poetry2": {
        paddingLeft: "2.5rem",
      },
      "&.poetry3": {
        paddingLeft: "3rem",
      },
      "&.poetry4": {
        paddingLeft: "3.5rem",
      },
      display: "block",
      width: "max-content",
      maxWidth: "min(90vw, 1191px)",
    };
    return (
      <Box component="span" key={item.verseNumber}>
        <Box
          sx={{
            display: "table",
            width: "max-content",
            maxWidth: { xs: "min(90vw, 1191px)", md: "min(90vw, 1150px)" },
          }}
        >
          <Box
            sx={{
              paddingTop: "4px",
              display: "inline",
            }}
          >
            <Box sx={verseNumber}>
              {verseNo}
              &nbsp;
            </Box>
            {getVerse(
              item,
              poetrySx,
              ftCount,
              footNotes,
              crCount,
              crossRefs,
              handlePop,
              footNote,
              crossRef
            )}
          </Box>
          {verseData?.includes?.(",") && <br />}
        </Box>
        {showHeading !== "skip" && getHeading(item, headingSx)}
      </Box>
    );
  }
  //multi verses, passage in a same chapter
  function getStyle(item) {
    let type = "";
    if (typeof item === "object" && "contents" in item) {
      type = item?.contents?.reduce((acc, cur) => {
        if (typeof cur === "object") {
          const tag = Object.keys(cur)[0];
          if (tag === "p") {
            return "p";
          }
          if (tag === "q1") {
            return "q1";
          }
          if (tag === "q2") {
            return "q2";
          }
        }
        return acc;
      }, "");
    }
    return type;
  }

  function splitParas(segments) {
    const paras = [];
    let i = 0;
    let lastTag = "p";
    for (const item of segments) {
      if (typeof paras[i] === "undefined") {
        paras[i] = { tag: lastTag, items: [] };
      }
      paras[i].items.push(item);
      lastTag = getStyle(item);
      if (lastTag !== "") {
        i++;
      }
    }
    return paras;
  }

  function displayBibleText(verses, chapter, verseData, bookDisplay) {
    ftCount.current = 0;
    crCount.current = 0;
    const paras = splitParas(verses);

    if (verseData !== "" && verseData?.match(/^[0-9,-]*$/g)) {
      return verseData?.split(",").map((element, i) => {
        const notLast = i !== verseData?.split(",").length - 1;
        return (
          <Box key={element + i}>
            {verseData?.indexOf(",") !== -1 && (
              // if multi sections show separate headings
              <Typography
                variant="button"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {`${bookDisplay} ${chapter}:${element}`}
              </Typography>
            )}
            {paras.map((para, paraIndex) => {
              let text = para?.items.map((item) =>
                showText(item, chapter, element)
              );
              return para.tag === "p" ? (
                <Box sx={{ textIndent: "unset" }} key={paraIndex}>
                  {text}
                </Box>
              ) : para.tag === "q1" ? (
                <Box sx={{ paddingLeft: "1rem" }} key={paraIndex}>
                  {text}
                </Box>
              ) : para.tag === "q2" ? (
                <Box sx={{ paddingLeft: "2.5rem" }} key={paraIndex}>
                  {text}
                </Box>
              ) : (
                <span key={paraIndex}>{text}</span>
              );
            })}
            {notLast ? (
              <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />
            ) : (
              ""
            )}
          </Box>
        );
      });
    } else {
      return paras.map((para, i) => {
        let text = para?.items.map((item) =>
          showText(item, chapter, verseData)
        );

        return para.tag === "p" ? (
          <Box
            sx={{
              textIndent: "unset",
              display: "block",
              width: "max-content",
              maxWidth: "min(90vw, 1191px)",
            }}
            key={i}
          >
            {text}
          </Box>
        ) : para.tag === "q1" ? (
          <Box
            sx={{
              paddingLeft: "1rem",
              display: "block",
              width: "max-content",
              maxWidth: "min(90vw, 1191px)",
            }}
            key={i}
          >
            {text}
          </Box>
        ) : para.tag === "q2" ? (
          <Box
            sx={{
              paddingLeft: "2.5rem",
              display: "block",
              width: "max-content",
              maxWidth: "min(90vw, 1191px)",
            }}
            key={i}
          >
            {text}
          </Box>
        ) : (
          <span key={i}>{text}</span>
        );
      });
    }
  }
  React.useEffect(() => {
    let splitSearchText = searchText?.split(";");
    let arrayRef = [];
    splitSearchText?.map((el) => {
      let result = bookList ? getReference(el, bookList) : "";
      if (el && bookList) {
        arrayRef.push(result);
      }
      return arrayRef;
    });
    setRefArray(arrayRef);
  }, [bookList, searchText]);
  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      setVersesArray([]);
      setError(false);
      setLoadingText(t("loadingMessage"));
      if (typeof cancelToken.current != typeof undefined) {
        cancelToken.current.cancel(t("readOperationCanceled"));
      }
      //Save the cancel token for the current request
      cancelToken.current = CancelToken.source();
      let verseArr = [];
      let errorArr = [];
      for (let el of refArray) {
        await API.get(
          `bibles/${sourceId}/books/${el?.bookCode}/chapter/${el?.chapter}`,
          {
            cancelToken: cancelToken.current.token,
          }
        )
          .then(function (response) {
            setIsLoading(false);
            if (response.data.chapterContent === undefined) {
              errorArr.push(true);
            } else {
              setLoadingText("");
              let contents = response.data.chapterContent.contents;
              let vNumArr = contents?.map((verse) => verse.verseNumber);
              let vNum = el?.verse;
              if (isNaN(vNum) || vNum === "") {
                if (vNum !== "" && vNum?.match(/^[0-9-]*$/g)) {
                  const [start, end] = vNum?.split("-");
                  if (
                    !vNumArr?.includes?.(start) ||
                    !vNumArr?.includes?.(end)
                  ) {
                    errorArr.push(true);
                  } else {
                    verseArr.push({ verses: contents, ref: el });
                  }
                } else {
                  verseArr.push({ verses: contents, ref: el });
                }
              } else {
                if (!vNumArr?.includes?.(vNum)) {
                  errorArr.push(true);
                } else {
                  verseArr.push({ verses: contents, ref: el });
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      }
      setError(errorArr.length === 0 ? false : true);
      setVersesArray(verseArr);
    };
    if (Array.isArray(refArray)) {
      fetch();
    }
  }, [refArray, sourceId, t, version]);

  React.useEffect(() => {
    if (bookList) {
      let book = bookList.find((element) => element.book_code === bookCode);
      if (book) {
        setBookDisplay(book.short);
      }
    }
    if (versesArray?.length > 0) {
      let bookchArr = [];
      versesArray?.map((ref) => {
        if (bookList) {
          let bookch = bookList.find(
            (element) => element.book_code === ref?.ref.bookCode
          );
          if (bookch) {
            let bcObj = bookch?.short;
            bookchArr.push(bcObj);
          }
        }
        return bookchArr;
      });
      setBookDisplayArray(bookchArr);
    }
  }, [bookList, bookCode, setBookDisplay, versesArray]);
  return (
    <>
      <TopBar />
      <Box
        flexGrow={1}
        sx={{
          display: { lg: "block", md: "flex", xs: "flex" },
          alignItems: { lg: "flex-start", md: "center", xs: "center" },
          position: "absolute",
          top: "75px",
          paddingLeft: "20px",
          borderBottom: "1px solid #f1ecec",
          height: 60,
          boxShadow: { xs: 1 },
          width: "100%",
        }}
      >
        <Version
          setValue={setValue1}
          version={version}
          bookCode={bookCode}
          chapter={chapter}
          verseData={verseData}
          language={language}
        />
        {isMobile ? (
          <Box
            sx={{
              position: "absolute",
              right: 0,
            }}
          >
            <Help
              iconStyle={{
                fontSize: 21,
                float: "right",
                marginRight: 1,
                color: color.BLACK,
              }}
              url={"searchBible"}
            />
          </Box>
        ) : (
          <Help
            iconStyle={{
              fontSize: 21,
              float: "right",
              marginRight: 1,
              color: color.BLACK,
              marginTop: 2.5,
            }}
            url={"searchBible"}
          />
        )}
      </Box>
      {!isLoading && loadingText !== t("readBookUploadedSoon") ? (
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
            margin: "10px",
            position: "absolute",
            top: "160px",
            bottom: 0,
            overflow: "auto",
            marginBottom: "-15px",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              borderRight: "1px solid #f7f7f7",
              overflow: "auto",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "12px 25px 30px",
                  margin: "auto",
                  maxWidth: "1191px",
                  [`@media print`]: {
                    fontSize: "1.2rem",
                  },
                  [theme.breakpoints.up("md")]: {
                    boxShadow: "0 2px 6px 0 hsl(0deg 0% 47% / 60%)",
                  },
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "50px",
                    padding: "0 0 50px 5px",
                  },
                }}
              >
                {versesArray?.map((element, i) => {
                  ref = {
                    book: bookDisplayArray[i],
                    chapter: element?.ref?.chapter,
                  };
                  return (
                    <div key={i}>
                      <Typography
                        variant="button"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                          fontSize: "20px",
                        }}
                      >
                        {bookDisplayArray[i]} {element?.ref?.chapter}
                        {element?.ref?.verse ? `:${element?.ref?.verse}` : ""}
                      </Typography>
                      <Box
                        sx={{
                          marginY: 1.3,
                        }}
                      >
                        {displayBibleText(element.verses, element.ref.verse)}
                      </Box>
                      <Button
                        id="button"
                        variant="outlined"
                        onClick={() => handleChapterRef(element?.ref)}
                        sx={{
                          display: "inline-flex",
                          fontSize: "1rem",
                          textTransform: "capitalize",
                          border: "1px solid #fff",
                          boxShadow: "1px 1px 1px 1px " + color.GREY,
                          marginY: 2.5,
                          borderRadius: 1,
                          color: color.BLACK,
                          borderColor: color.BLACK,
                        }}
                      >
                        {t("readChapterBtnSearchPassage", { ref })}
                      </Button>
                    </div>
                  );
                })}

                <br />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    width: "calc(100% - 20px)",
                  }}
                >
                  {error ? t("searchErrorMessage") : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      ) : searchText.length === 0 ? (
        <LoadingHeading sx={{ fontSize: "21px" }}>
          {t("noSearchError")}
        </LoadingHeading>
      ) : (
        <LoadingHeading>{loadingText}</LoadingHeading>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    panel1: state.local.panel1,
    versionBooks: state.local.versionBooks,
    versionSource: state.local.versionSource,
    searchText: state.local.searchText,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setValue1: (name, value) =>
      dispatch({ type: actions.SETVALUE1, name: name, value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchMultiRef);
