import { Grid, Box, IconButton, Typography } from "@mui/material";
import { BLACK, LIGHTGREY } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SETVALUE } from "../../store/actions";
import {
  COMMENTARY,
  HIGHLIGHT,
  INFOGRAPHICS,
  PARALLELBIBLE,
  READINGPLANS,
  SIGNBIBLE,
} from "../../store/views";
import { connect } from "react-redux";
import { styled } from "@mui/system";

const Icon = styled("i")({
  padding: "10px",
  color: BLACK,
});

const FeaturesBox = (props) => {
  const { setParallelView, locale } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        backgroundColor: LIGHTGREY,
      }}
    >
      <Box
        component="h2"
        sx={{
          padding: { xs: "20px", sm: "40px" },
          pt: { xs: "50px", sm: "60px" },
          textAlign: "center",
          fontSize: { xs: locale === "ml" ? "21px" : "25px", sm: "2.5rem" },
        }}
      >
        {t("featuresDesc1")}
      </Box>
      <Box
        justifyContent="center"
        sx={{
          display: "flex",
          flexGrow: 1,
          paddingBottom: "50px",
          fontSize: "1.25rem",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 6, md: 12 }}
        >
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(COMMENTARY)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                comment
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("commentariesText")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(HIGHLIGHT)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                border_color
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("highlightsText")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(PARALLELBIBLE)}
            >
              <Icon
                style={{
                  fontSize: "60px",
                  textDecoration: "none",
                }}
                className={`material-icons`}
              >
                import_contacts
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("parallelBibleText")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(INFOGRAPHICS)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                image
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("infographicsText")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(READINGPLANS)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                event
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("readingPlansText")}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "grid" }}>
            <IconButton
              variant="contained"
              size="large"
              color="inherit"
              component={Link}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              to={"/study"}
              onClick={() => setParallelView(SIGNBIBLE)}
            >
              <Icon style={{ fontSize: "60px" }} className={`material-icons`}>
                sign_language
              </Icon>
            </IconButton>
            <Typography
              sx={{
                fontSize: "15px",
              }}
            >
              {t("ISLVBibleText")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    parallelView: state.local.parallelView,
    locale: state.local.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setParallelView: (value) =>
      dispatch({ type: SETVALUE, name: "parallelView", value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FeaturesBox);
