import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { BLACK, WHITE } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Menu,
  Snackbar,
  TextField,
  popoverClasses,
} from "@mui/material";
import moment from "moment";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { connect } from "react-redux";

function PlanInfo({ firebaseData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareAnchor, setShareAnchor] = React.useState(null);
  const [copyFeedback, setCopyFeedback] = React.useState("");
  const [alert, setAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [anchorElArchivePlan, setAnchorElArchivePlan] = useState(null);

  //metadata information popup
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleArchiveClose() {
    setAnchorElArchivePlan(null);
  }
  const closeAlert = () => {
    setAlert(false);
  };
  function openShareDialog(event) {
    setShareAnchor(event.currentTarget);
  }
  function closeShareDialog() {
    setShareAnchor(null);
  }
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textContent);
      setAlert(true);
      setCopyFeedback(t("clipBoardCopied"));
      setAlertType("success");
      closeShareDialog();
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      setAlert(true);
      setCopyFeedback(t("clipBoardCopiedFailed"));
      setAlertType("error");
      closeShareDialog();
    }
  };
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const openArchivePlan = Boolean(anchorElArchivePlan);
  const openShare = Boolean(shareAnchor);
  const textContent =
    t("planName") +
    ": " +
    firebaseData.planLabel +
    "\n" +
    t("startDate") +
    ": " +
    moment(firebaseData.startedDate, "DD/MM/YYYY").format("DD/MM/YYYY") +
    "\n" +
    t("expectedTime") +
    ": " +
    moment(firebaseData.expectedCompletedDate, "DD/MM/YYYY").format(
      "DD/MM/YYYY"
    ) +
    "\n" +
    t("readings") +
    ": " +
    firebaseData.completedReadings +
    "/" +
    firebaseData.totalReadings +
    "\n" +
    t("days") +
    ": " +
    firebaseData.completedDays +
    "/" +
    firebaseData.totalDays;
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Tooltip title={t("informationText")}>
        <Box
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            padding: 0,
            width: "20px",
            marginTop: {xs: "11px", md: 1},
            marginRight: 0.5,
            color: BLACK,
            cursor: "pointer",
          }}
        >
          <i className="material-icons md-23">info_outline</i>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          [`& .${popoverClasses.paper}`]: {
            width: { lg: "40%", xs: "95%" },
            minWidth: { md: 600, xs: "unset" },
          },
        }}
      >
        <Paper>
          <Grid
            justifyContent="space-between"
            container
            sx={{
              boxShadow: 4,
              marginBottom: 0.5,
              color: BLACK,
            }}
          >
            <Grid item>
              <Typography
                type="title"
                color="inherit"
                sx={{
                  fontSize: "1.3em",
                  paddingTop: 1.375,
                  paddingBottom: 0,
                  paddingLeft: 1.5,
                  paddingRight: 0,
                }}
              >
                {t("planDetail")}
                <ShareIcon
                  fontSize="small"
                  onClick={openShareDialog}
                  sx={{
                    color: BLACK,
                    cursor: "pointer",
                    textShadow: "1px 1px 2px " + BLACK,
                    marginTop: "-1px",
                    marginLeft: "8px",
                  }}
                />
              </Typography>
            </Grid>
            <Menu
              id="long-menu"
              anchorEl={shareAnchor}
              keepMounted
              open={openShare}
              onClose={closeShareDialog}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{
                "& .MuiMenu-paper": {
                  maxHeight: "450px",
                  marginTop: "6px",
                  width: "420px",
                  alignItems: "left",
                  paddingLeft: "12px",
                },
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="share-url"
                  variant="outlined"
                  size="small"
                  value={textContent}
                  sx={{
                    width: "94%",
                    margin: "7px",
                    color: WHITE,
                  }}
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  onFocus={(e) => e.target.select()}
                />
                <Button
                  sx={{
                    textTransform: "capitalize",
                    margin: "10px auto",
                    color: BLACK,
                    borderColor: BLACK,
                    "&:hover": {
                      backgroundColor: BLACK + "0a",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    },
                  }}
                  variant="outlined"
                  onClick={handleCopyClick}
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  {t("copyToClipBoardBtn")}
                </Button>
                <Box display={"flex"} justifyContent={"center"}>
                  <FacebookShareButton
                    url={window.location.href}
                    hashtag={textContent}
                    style={{ margin: "0 5px" }}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url={window.location.href}
                    separator=" "
                    title={textContent}
                    style={{ margin: "0 5px" }}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={window.location.href}
                    body={textContent}
                    style={{ margin: "0 5px" }}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={textContent}
                    style={{ margin: "0 5px" }}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </Box>
              </div>
            </Menu>
            <Grid item>
              <IconButton
                sx={{
                  color: "inherit",
                }}
                size="medium"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              maxHeight: { sm: "80vh" },
              overflow: { sm: "scroll" },
              padding: 1,
            }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              sx={{
                paddingLeft: 0.625,
                ":last-child": {
                  marginBottom: 0.25,
                },
                ":nth-child(even)": {
                  backgroundColor: "#eaeaea",
                },
              }}
            >
              <Grid
                item
                md={4}
                sx={{
                  fontSize: 17,
                  lineHeight: "28px",
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {t("planName")} :
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  lineHeight: "28px",
                  fontSize: 16,
                  paddingLeft: { xs: 0.625, lg: 1.75 },
                }}
              >
                {firebaseData.planLabel}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              sx={{
                paddingLeft: 0.625,
                ":last-child": {
                  marginBottom: 0.25,
                },
                ":nth-child(even)": {
                  backgroundColor: "#eaeaea",
                },
              }}
            >
              <Grid
                item
                md={4}
                sx={{
                  fontSize: 17,
                  lineHeight: "28px",
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {t("startDate")} :
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  lineHeight: "28px",
                  fontSize: 16,
                  paddingLeft: { xs: 0.625, lg: 1.75 },
                }}
              >
                {moment(firebaseData.startedDate, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              sx={{
                paddingLeft: 0.625,
                ":last-child": {
                  marginBottom: 0.25,
                },
                ":nth-child(even)": {
                  backgroundColor: "#eaeaea",
                },
              }}
            >
              <Grid
                item
                md={4}
                sx={{
                  fontSize: 17,
                  lineHeight: "28px",
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {t("expectedTime")} :
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  lineHeight: "28px",
                  fontSize: 16,
                  paddingLeft: { xs: 0.625, lg: 1.75 },
                }}
              >
                {moment(
                  firebaseData.expectedCompletedDate,
                  "DD/MM/YYYY"
                ).format("DD/MM/YYYY")}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              sx={{
                paddingLeft: 0.625,
                ":last-child": {
                  marginBottom: 0.25,
                },
                ":nth-child(even)": {
                  backgroundColor: "#eaeaea",
                },
              }}
            >
              <Grid
                item
                md={4}
                sx={{
                  fontSize: 17,
                  lineHeight: "28px",
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {t("readings")} :
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  lineHeight: "28px",
                  fontSize: 16,
                  paddingLeft: { xs: 0.625, lg: 1.75 },
                }}
              >
                {firebaseData.completedReadings}/{firebaseData.totalReadings}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              sx={{
                paddingLeft: 0.625,
                ":last-child": {
                  marginBottom: 0.25,
                },
                ":nth-child(even)": {
                  backgroundColor: "#eaeaea",
                },
              }}
            >
              <Grid
                item
                md={4}
                sx={{
                  fontSize: 17,
                  lineHeight: "28px",
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {t("days")} :
              </Grid>
              <Grid
                item
                md={8}
                sx={{
                  lineHeight: "28px",
                  fontSize: 16,
                  paddingLeft: { xs: 0.625, lg: 1.75 },
                }}
              >
                {firebaseData.completedDays}/{firebaseData.totalDays}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          open={alert}
          autoHideDuration={800}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={closeAlert}
            severity={alertType}
          >
            {copyFeedback}
          </Alert>
        </Snackbar>
      </Popover>
      <Popover
        id={id}
        open={openArchivePlan}
        anchorEl={anchorElArchivePlan}
        onClose={handleArchiveClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          [`& .${popoverClasses.paper}`]: {
            width: { lg: "30%", xs: "95%" },
            minWidth: { md: 400, xs: "unset" },
          },
        }}
      ></Popover>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.local.userDetails,
  };
};
export default connect(mapStateToProps)(PlanInfo);
