import { InputBase, Paper, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { connect } from "react-redux";
import { SETVALUE, SETVALUE1 } from "../../store/actions";
import { BLACK } from "../../store/colorCode";
import { getReference } from "../common/utility";
import BigTooltip from "../common/BigTooltip";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { SEARCH } from "../../store/views";
import { useTheme } from "@emotion/react";

const Icon = styled("i")({
  padding: "1px 1px 1px",
  color: BLACK,
  fontSize: "33px",
});
const SearchPassage = (props) => {
  const location = useLocation();
  const path = location?.pathname;
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    mobileView,
    setValue1,
    setValue,
    panel1,
    versionBooks,
    versionSource,
    setMainValue,
    searchText,
    isSearchFocus,
  } = props;
  const [searchTextRef, setSearchTextRef] = React.useState(searchText);
  const { t } = useTranslation();
  const bookList = versionBooks[versionSource[panel1.sourceId]];
  const searchHints = (
    <div>
      <h5>{t("searchHints")}:-</h5>
      <h6>
        <b>{t("multiSearch")}:</b>
      </h6>
      <span>
        <i>{t("egSearchRef")}</i> gen 49:1-5; gen 1; jhn 1:3
      </span>
      <h6>
        <b>{t("chapterSearch")}:</b>
      </h6>
      <span>
        <i>{t("egSearchRef")}</i> gen 49 or നഹൂം 1 or यहूदा 1
      </span>
      <h6>
        <b>{t("verseSearchRef")}:</b>
      </h6>
      <span>
        <i>{t("egSearchRef")}:</i> ഇയ്യോബ് 42:2 or genesis 12:2,3 or रूत 2:12
      </span>
      <h6>
        <b>{t("passageSearch")}</b>
      </h6>
      <span>
        <i>{t("egSearchRef")}: </i>rev 1:13-16 or 1 योहान 4:8-10,16,20-25
      </span>
      <h6>
        <b>{t("keywordSearch")}</b>
      </h6>
      <span>
        <i>{t("egSearchRef")}: </i>
        moses or मूसा or മോശെ
      </span>
    </div>
  );

  function clearTextField() {
    setSearchTextRef("");
    setValue("searchText", "");
    setValue("isSearchFocus", false);
  }
  const handleSearchTextChange = (event) => {
    setSearchTextRef(event.target.value);
  };

  function showSearchResult(event) {
    event.preventDefault();
    if (searchTextRef === "") {
      return;
    }
    setValue("errorMessage", "");
    const search = searchTextRef;
    setValue("searchText", search);
    // check search text is string or not
    if (!/\d/.test(search)) {
      if (path === "/" || path === "/search") {
        navigate("/study");
        setMainValue("parallelView", SEARCH);
      } else {
        setValue("errorMessage", "textSearch");
      }
      return;
    }
    if (search) {
      if (search.includes(";")) {
        setValue("searchText", search);
        navigate(`/search`);
      }
      if (path === "/read" && search.includes(";")) {
        navigate("/search");
      }
      if (!search.includes(";")) {
        navigate("/read");
        const ref = getReference(search, bookList);
        if (ref === "bookNotFound") {
          setValue("errorMessage", "bookNotFound");
        } else if (ref === "chapterNotFound") {
          setValue("errorMessage", "referenceNotFound");
        } else if (ref === "invalidFormat") {
          setValue("errorMessage", "invalidFormat");
        }
        if (ref && typeof ref === "object") {
          setValue1("chapter", ref.chapter);
          setValue1("bookCode", ref.bookCode);
          setValue1("verseData", ref.verse);
          setValue("errorMessage", "");
        }
      }
    }
  }

  return !isSearchFocus ? (
    <>
      <BigTooltip
        disableFocusListener={mobileView}
        title={searchHints}
        sx={{
          width: { lg: 350, xs: 300 },
        }}
      >
        <Paper
          component="form"
          onSubmit={showSearchResult}
          sx={{
            paddingX: 0.4,
            paddingY: 0.4,
            display: "flex",
            alignItems: "center",
            height: 40,
            width: { sm: 350, xs: 250 },
            marginLeft: { lg: 2.5, xs: 0 },
            marginRight: { lg: 1.25, xs: 0 },
            [theme.breakpoints.down(450)]: {
              width: 150,
            },
          }}
        >
          <IconButton type="submit" size="large">
            <Icon className="material-icons">manage_search</Icon>
          </IconButton>
          <InputBase
            sx={{
              marginLeft: 1,
              flex: 1,
              width: { lg: "auto", xs: 155 },
              "& .MuiInputBase-input": { height: "80px" },
            }}
            placeholder={
              window.innerWidth > 450
                ? t("searchPlaceHolderTopbar")
                : t("searchPlaceHolderTopbarSmall")
            }
            value={searchTextRef}
            name="search"
            autoComplete="off"
            onChange={handleSearchTextChange}
            onFocus={() => {
              if (window.innerWidth < 450) setValue("isSearchFocus", true);
            }}
          />
          {searchTextRef && (
            <CloseIcon onClick={clearTextField} sx={{ cursor: "pointer" }} />
          )}
        </Paper>
      </BigTooltip>
    </>
  ) : (
    <>
      <BigTooltip
        disableFocusListener={mobileView}
        title={searchHints}
        sx={{ width: "100%" }}
      >
        <Paper
          component="form"
          onSubmit={showSearchResult}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 40,
            width: "97%",
            position: "absolute",
            zIndex: "9",
            [theme.breakpoints.down(380)]: {
              marginLeft: "-5px",
            },
          }}
        >
          <IconButton type="submit" size="large">
            <Icon className="material-icons">manage_search</Icon>
          </IconButton>
          <div style={{ display: "flex", flex: 1 }}>
            <InputBase
              sx={{
                marginLeft: 1,
                flex: 0.8,
                "& .MuiInputBase-input": { height: "80px" },
              }}
              placeholder={
                window.innerWidth > 450
                  ? t("searchPlaceHolderTopbar")
                  : t("searchPlaceHolderTopbarSmall")
              }
              value={searchTextRef}
              name="search"
              autoComplete="off"
              onChange={handleSearchTextChange}
            />
          </div>
          <Button
            sx={{
              flex: 0.2,
              textTransform: "capitalize",
              fontWeight: "bold",
              paddingRight: "20px",
              height: "100%",
              color: BLACK,
            }}
            onClick={clearTextField}
          >
            {t("commonCancel")}
          </Button>
        </Paper>
      </BigTooltip>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    panel1: state.local.panel1,
    versionBooks: state.local.versionBooks,
    versionSource: state.local.versionSource,
    mobileView: state.local.mobileView,
    searchText: state.local.searchText,
    isSearchFocus: state.local.isSearchFocus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setValue1: (name, value) =>
      dispatch({ type: SETVALUE1, name: name, value: value }),
    setValue: (name, value) =>
      dispatch({ type: SETVALUE, name: name, value: value }),
    setMainValue: (name, value) =>
      dispatch({ type: SETVALUE, name: name, value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchPassage);
