export const PARALLELBIBLE = "PARALLELBIBLE";
export const COMMENTARY = "COMMENTARY";
export const DICTIONARY = "DICTIONARY";
export const INFOGRAPHICS = "INFOGRAPHICS";
export const AUDIO = "AUDIO";
export const VIDEO = "VIDEO";
export const BOOKMARK = "BOOKMARK";
export const HIGHLIGHT = "HIGHLIGHT";
export const NOTE = "NOTE";
export const SEARCH = "SEARCH";
export const READINGPLANS = "READINGPLANS";
export const SIGNBIBLE = "SIGNBIBLE";
export const HISTORY = "HISTORY";
export const DRAWERCOMMENTARY = "DRAWERCOMMENTARY";
export const DRAWERSIGNBIBLE = "DRAWERSIGNBIBLE";
export const MOBILEPV = [PARALLELBIBLE, COMMENTARY, READINGPLANS, SEARCH];
