import React, { useState } from "react";
import TopBar from "../read/TopBar";
import {
  Box,
  Card,
  Dialog,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { WHITE } from "../../store/colorCode";
import { capitalize, getBookbyCode } from "../common/utility";
import { languageJson } from "../../store/languageData";
import DeletePopup from "./DeletePopup";
import useIndexedDB from "./useIndexedDB";

function History(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [deletePopup, setDeletePopup] = useState(false);

  const { data: urlHistory, deleteData, clearStore } = useIndexedDB();

  const getDate = (date) => {
    let todayDay = moment();
    let startDate = moment(date);
    let diff = todayDay.diff(startDate, "days");
    if (diff === 0) {
      return "Today";
    } else if (diff === 1) {
      return "Yesterday";
    } else {
      return `${diff + 1} days ago`;
    }
  };

  const getLanguageName = (abbreviation) => {
    return (
      capitalize(
        languageJson.find((element) => element.langCode === abbreviation)
          ?.language
      ) || ""
    );
  };

  const handleDelete = (option) => {
    setDeletePopup(false);
    if (option === "today") {
      urlHistory.forEach((url) => {
        let todayDay = moment();
        let startDate = moment(url.date);
        let diff = todayDay.diff(startDate, "days");
        if (diff === 0) {
          deleteData(url.id);
        }
      });
    } else {
      clearStore();
    }
  };

  return (
    <>
      <TopBar />
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          top: { lg: 72, sm: 62, xs: 10 },
          bottom: 0,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "10px 0px",
            position: "fixed",
            zIndex: 9,
            right: "0px",
            background: WHITE,
            [theme.breakpoints.only("xs")]: {
              width: "100%",
              right: "0px",
              paddingTop: "50px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { lg: "1.7rem", xs: "1.2rem" },
                textAlign: "center",
                flex: 1,
                marginTop: "13px",
              }}
            >
              {t("historyTopBarBtn")}
            </Typography>
            <Box
              sx={{
                position: { lg: "absolute", xs: "static" },
                right: 25,
                marginTop: { xs: "10px", md: "unset" },
              }}
            >
              <Tooltip title={t("clearHistoryTopBarBtn")}>
                <DeleteIcon
                  onClick={() => {
                    setDeletePopup(true);
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            left: 0,
            top: 105,
            bottom: 0,
            overflow: "scroll",
            mb: -1.875,
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0,0,0,.4) #eeeeee95",
            "-webkit-scrollbar": {
              width: "0.45em",
            },
            "-webkit-scrollbar-track": {
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.4)",
              outline: "1px solid slategrey",
            },
          }}
        >
          <Box
            sx={{
              maxWidth: "1200px",
              margin: "0px auto",
            }}
          >
            {urlHistory && urlHistory.length > 0 ? (
              <Card variant="outlined" sx={{ boxShadow: 3 }} component="nav">
                {urlHistory.map((url, i) => (
                  <ListItem
                    onClick={() => (window.location.href = `/read${url.url}`)}
                    key={url.id}
                    sx={{
                      borderBottom: "1px solid lightgray",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      [theme.breakpoints.down("600")]: {
                        alignItems: "flex-start",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        wordBreak: "break-all",
                        marginRight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {getLanguageName(url?.version?.split("-")[0])}{" "}
                      {url?.version?.split("-")[1]}{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      {getBookbyCode(url?.bookCode || "")?.book} {url?.chapter}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "gray",
                      }}
                    >
                      {getDate(url?.date)}
                    </Typography>
                  </ListItem>
                ))}
              </Card>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={deletePopup}
        onClose={() => {
          setDeletePopup(false);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DeletePopup
          handleClose={() => {
            setDeletePopup(false);
          }}
          deleteData={handleDelete}
        />
      </Dialog>
    </>
  );
}

export default History;
