import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Close from "../common/Close";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Dialog, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import { capitalize, getBookbyCode } from "../common/utility";
import { languageJson } from "../../store/languageData";
import DeletePopup from "./DeletePopup";
import useIndexedDB from "./useIndexedDB";

const SidePanelHistory = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [deletePopup, setDeletePopup] = useState(false);

  const {
    data: urlHistory,
    fetchData,
    deleteData,
    clearStore,
  } = useIndexedDB();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getDate = (date) => {
    let todayDay = moment();
    let startDate = moment(date);
    let diff = todayDay.diff(startDate, "days");
    if (diff === 0) {
      return "Today";
    } else if (diff === 1) {
      return "Yesterday";
    } else {
      return `${diff + 1} day ago`;
    }
  };

  const getLanguageName = (abbreviation) => {
    return (
      capitalize(
        languageJson.find((element) => element.langCode === abbreviation)
          .language
      ) || ""
    );
  };

  const handleDelete = (option) => {
    setDeletePopup(false);
    if (option === "today") {
      urlHistory.forEach((url) => {
        let todayDay = moment();
        let startDate = moment(url.date);
        let diff = todayDay.diff(startDate, "days");
        if (diff === 0) {
          deleteData(url.id);
        }
      });
    } else {
      clearStore();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: { xs: 7.75, lg: 11 },
      }}
    >
      <Box
        sx={{
          pb: { xs: 0, lg: 1.25 },
          pl: 1.875,
          mb: { sm: 0, lg: 2.5 },
          borderBottom: "1px solid #f1ecec",
          display: "flex",
          width: "100%",
          alignItems: { xs: "center" },
          height: { xs: 60, lg: 44 },
        }}
      >
        <Box flexGrow={1}>
          <Typography variant="h6">{t("historyTopBarBtn")}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={t("clearHistoryTopBarBtn")}>
            <DeleteIcon
              sx={{ marginTop: { lg: "-8px", xs: "0px" } }}
              onClick={() => {
                setDeletePopup(true);
              }}
            />
          </Tooltip>
          <Close
            sx={{
              marginRight: { lg: "10px", md: "15px" },
              marginTop: { lg: "-8px", md: "-4px" },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          left: 0,
          top: { xs: 120, lg: 135 },
          bottom: 0,
          overflow: "scroll",
          mb: -1.875,
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0,0,0,.4) #eeeeee95",
          "-webkit-scrollbar": {
            width: "0.45em",
          },
          "-webkit-scrollbar-track": {
            WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.4)",
            outline: "1px solid slategrey",
          },
        }}
      >
        {urlHistory &&
          urlHistory.length !== 0 &&
          urlHistory.map((url, index) => {
            return (
              <List component="nav" key={index}>
                <ListItem
                  onClick={() => (window.location.href = `/read${url.url}`)}
                  sx={{
                    borderBottom: "1px solid lightgray",
                    cursor: "pointer",
                    padding: "4px 8px",
                    display: "flex",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("600")]: {
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <ListItemText
                    primary={`
                      ${getLanguageName(url?.version.split("-")[0])} 
                      ${url?.version.split("-")[1]}`}
                    secondary={`${getBookbyCode(url?.bookCode || "")?.book} ${
                      url?.chapter
                    }`}
                    sx={{
                      display: "flex",
                      "& .MuiListItemText-primary": {
                        fontWeight: "600",
                      },
                      "& .MuiListItemText-secondary": {
                        fontSize: "1rem",
                        marginLeft: 1,
                        color: "unset",
                      },
                    }}
                  />
                  <ListItemText
                    sx={{
                      color: "gray",
                      [theme.breakpoints.up("600")]: {
                        textAlign: "right",
                      },
                    }}
                    primary={getDate(url?.date)}
                  />
                </ListItem>
              </List>
            );
          })}
      </Box>
      <Dialog
        open={deletePopup}
        onClose={() => {
          setDeletePopup(false);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DeletePopup
          handleClose={() => {
            setDeletePopup(false);
          }}
          deleteData={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default SidePanelHistory;
