import { useState, useEffect, useCallback } from "react";

function useIndexedDB() {
  const [data, setData] = useState([]);
  const [db, setDb] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const dbName = "UrlHistoryDB";
  const storeName = "urlHistory";

  const fetchData = useCallback((db) => {
    if (!db) return;
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = (event) => {
      setData(event.target.result);
    };

    request.onerror = (event) => {
      console.error("Error fetching data:", event.target.error);
    };
  }, []);
  useEffect(() => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      setDb(db);
      fetchData(db);
    };

    request.onerror = (event) => {
      console.error("IndexedDB error:", event.target.error);
    };
  }, [fetchData]);

  useEffect(() => {
    if (db) {
      fetchData(db);
    }
  }, [db, refreshTrigger, fetchData]);
  const addData = useCallback(
    (item) => {
      if (!db) return;
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      store.add(item);

      tx.oncomplete = () => {
        fetchData(db);
        setRefreshTrigger((prev) => !prev);
      };

      tx.onerror = (event) => {
        console.error("Error adding data:", event.target.error);
      };
    },
    [db, fetchData]
  );

  const deleteData = useCallback(
    (id) => {
      if (!db) return;
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      store.delete(id);

      tx.oncomplete = () => {
        fetchData(db);
        setRefreshTrigger((prev) => !prev);
      };

      tx.onerror = (event) => {
        console.error("Error deleting data:", event.target.error);
      };
    },
    [db, fetchData]
  );

  const clearStore = useCallback(() => {
    if (!db) return;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.clear();

    tx.oncomplete = () => {
      setData([]);
      setRefreshTrigger((prev) => !prev);
    };

    tx.onerror = (event) => {
      console.error("Error clearing store:", event.target.error);
    };
  }, [db]);

  return {
    data,
    fetchData: () => fetchData(db),
    addData,
    deleteData,
    clearStore,
  };
}

export default useIndexedDB;
