import React from "react";
import Highlight from "../highlight/Highlight";
import CloseIcon from "@mui/icons-material/Close";
import Note from "../note/Note";
import {
  Box,
  Tooltip,
  Snackbar,
  Alert,
  Menu,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BLACK } from "../../store/colorCode";
import { API } from "../../store/api";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import XIcon from "@mui/icons-material/X";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
} from "react-share";

export default function BottomToolBar(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userDetails,
    selectedVerses,
    setSelectedVerses,
    refUrl,
    highlights,
    sourceId,
    bookCode,
    chapter,
    version,
  } = props;
  const [highlightIcon, setHighlightIcon] = React.useState("");
  const [noteIcon, setNoteIcon] = React.useState("");
  const [shareAnchor, setShareAnchor] = React.useState(null);
  const [copyFeedback, setCopyFeedback] = React.useState("");
  const [alert, setAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [referenceData, setReferenceData] = React.useState();
  function clearSelection() {
    setSelectedVerses([]);
  }
  React.useEffect(() => {
    if (userDetails.uid !== null) {
      if (selectedVerses?.length > 0) {
        setHighlightIcon(
          <Highlight
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            refUrl={refUrl}
            highlights={highlights}
          />
        );
        return;
      }
    } else {
      setHighlightIcon("");
    }
  }, [userDetails, selectedVerses, setSelectedVerses, refUrl, highlights]);
  //Set note icon
  React.useEffect(() => {
    if (userDetails.uid !== null) {
      if (selectedVerses?.length > 0) {
        setNoteIcon(
          <Note
            uid={userDetails.uid}
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            sourceId={sourceId}
            bookCode={bookCode}
            chapter={chapter}
          />
        );
        return;
      }
    } else {
      setNoteIcon("");
    }
  }, [
    userDetails,
    selectedVerses,
    setSelectedVerses,
    sourceId,
    bookCode,
    chapter,
  ]);

  const openShareDialog = (event) => {
    setShareAnchor(event.currentTarget);
  };

  const closeShareDialog = () => {
    setShareAnchor(null);
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(`${description}\n${baseUrl}`);
      setAlert(true);
      setCopyFeedback(t("clipBoardCopied"));
      setAlertType("success");
      closeShareDialog();
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      setAlert(true);
      setCopyFeedback(t("clipBoardCopiedFailed"));
      setAlertType("error");
      closeShareDialog();
    }
  };

  const closeAlert = () => {
    setAlert(false);
  };

  const baseUrl = `${
    window.location.href.split(/\/(study|read)/)[0]
  }/read?version=${version}&reference=${bookCode}.${chapter}.${
    selectedVerses[0]
  }`;

  React.useEffect(() => {
    if (sourceId) {
      API.get(
        `bibles/${sourceId}/verses/${bookCode}.${chapter}.${selectedVerses[0]}`
      ).then((response) => {
        setDescription(response.data?.verseContent?.text);
        setReferenceData(
          `${response.data?.reference?.replaceAll(": ", ":")} - ${
            version.split("-")[1]
          } - VachanOnline`
        );
      });
    }
  }, [bookCode, chapter, selectedVerses, sourceId, version]);
  
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          boxShadow: "0 -1px 4px #b3b6bf",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.up("xs")]: {
            width: "370px",
            padding: "0 10px",
          },
          backgroundColor: "white",
          height: "50px",
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          {highlightIcon}
          <Tooltip title={t("comparisonTopBarBtn")}>
            <CompareArrowsIcon
              fontSize="small"
              sx={{
                color: BLACK,
                fontSize: 35,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/comparison?language=${version.split("-")[0]}&verse=${
                    selectedVerses[0]
                  }&bookCode=${bookCode}&chapter=${chapter}`
                );
              }}
            />
          </Tooltip>
          <Box
            sx={{ marginBottom: "20px", marginLeft: "5px", marginRight: "5px" }}
          >
            {noteIcon}
          </Box>
          {selectedVerses.length === 1 && (
            <Box>
              <Tooltip title={t("shareTooltip")}>
                <ShareIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={openShareDialog}
                />
              </Tooltip>
              <Menu
                id="long-menu"
                anchorEl={shareAnchor}
                keepMounted
                open={Boolean(shareAnchor)}
                onClose={closeShareDialog}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: 320,
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[5],
                  },
                }}
              >
                <Box sx={{ p: 2 }}>
                  <TextField
                    id="share-url"
                    size="small"
                    value={`${description}\n${baseUrl}`}
                    sx={{ width: "100%", mb: 1 }}
                    InputProps={{
                      readOnly: true,
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      textTransform: "capitalize",
                      margin: "10px auto",
                      color: BLACK,
                      borderColor: BLACK,
                      "&:hover": {
                        backgroundColor: BLACK + "0a",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                      },
                    }}
                    onClick={handleCopyClick}
                    startIcon={<FileCopyOutlinedIcon />}
                  >
                    {t("copyToClipBoardBtn")}
                  </Button>
                  {description && referenceData ? (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <FacebookShareButton
                        url={baseUrl}
                        hashtag={referenceData}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <WhatsappShareButton url={baseUrl} title={description}>
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <EmailShareButton url={baseUrl} body={description}>
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                      <TwitterShareButton url={baseUrl} title={description}>
                        <XIcon size={32} round />
                      </TwitterShareButton>
                      <LinkedinShareButton url={baseUrl}>
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Menu>
            </Box>
          )}
        </Box>
        <Box onClick={clearSelection}>
          <CloseIcon sx={{ cursor: "pointer" }} />
        </Box>
      </Box>
      <Snackbar
        open={alert}
        autoHideDuration={800}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeAlert}
          severity={alertType}
        >
          {copyFeedback}
        </Alert>
      </Snackbar>
    </Box>
  );
}
