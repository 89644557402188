import React, { Suspense } from "react";
import Landing from "./components/landing/Landing";
import ReadBible from "./components/read/ReadBible";
import StudyBible from "./components/read/StudyBible";
import Stories from "./components/stories/Stories";
import PrivacyPolicy from "./components/privacypolicy/PrivacyPolicy";
import "./components/common/common.css";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Songs from "./components/songs/Songs";
import AudioBible from "./components/audio/AudioBible";
import UILanguageDialog from "./components/common/UILanguageDialog";
import Comparison from "./components/comparison/Comparison";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import SearchMultiRef from "./components/search/SearchMultiRef";
import History from "./components/history/History";
import InfographicsPage from "./components/infographics/InfographicsPage";
import VideosPage from "./components/video/VideosPage";

const theme = createTheme();

function Loading() {
  return <>Loading...</>;
}
const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <CssBaseline />
          <UILanguageDialog />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/read" element={<ReadBible />} />
            <Route path="/study" element={<StudyBible />} />
            <Route path="/biblestories" element={<Stories />} />
            <Route path="/audiobible" element={<AudioBible />} />
            <Route path="/infographics" element={<InfographicsPage />} />
            <Route path="/videos" element={<VideosPage />} />
            <Route path="/songs" element={<Songs />} />
            <Route path="/search" element={<SearchMultiRef />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/comparison" element={<Comparison />} />
            <Route path="/history" element={<History />} />
            <Route render={() => <h1>Page Not Found</h1>} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  </StyledEngineProvider>
);
export default App;
