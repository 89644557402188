import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { BLACK } from "../../store/colorCode";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputBase from "@mui/material/InputBase";
import ReactDatePicker from "react-datepicker";
import { styled } from "@mui/system";

const CreatePlan = ({ readingPlan, handleClose, createFirebasePlan }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [planData, setPlanData] = useState(readingPlan);
  const StyledDatePicker = styled(ReactDatePicker)(({ theme }) => ({
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    [theme.breakpoints.down("sm")]: { width: 120, marginTop: 6 },
  }));
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      aria-haspopup="true"
      variant="contained"
      onClick={onClick}
      ref={ref}
      sx={{
        [`&.MuiButton-root`]: {
          textTransform: "capitalize",
          backgroundColor: "#fff",
        },
        color: BLACK,
      }}
    >
      {value}
    </Button>
  ));
  return (
    <>
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          boxShadow: 4,
        }}
      >
        <Box
          sx={{
            marginBottom: "-10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" gutterBottom>
            {t("createPlan")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          margin: "40px 0px",
          maxHeight: "350px",
        }}
      >
        <Box
          sx={{
            width: "355px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              width: "180px",
              flexDirection: "column",
            },
          }}
        >
          <Typography sx={{ fontWeight: "800" }} variant="p" gutterBottom>
            {t("planName")}
          </Typography>
          <Box>
            <InputBase
              sx={{
                width: 140,
                ".MuiInputBase-input": {
                  textAlign: { xs: "start", sm: "end" },
                },
              }}
              placeholder={t("planName")}
              value={planData.planLabel}
              onChange={(e) => {
                setPlanData((prevState) => {
                  let plan = { ...prevState };
                  plan.planLabel = e.target.value;
                  return plan;
                });
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "355px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              width: "180px",
              flexDirection: "column",
            },
          }}
        >
          <Typography sx={{ fontWeight: "800" }} variant="p" gutterBottom>
            {t("startDate")}
          </Typography>
          <StyledDatePicker
            selected={planData.startedDate}
            onChange={(date) => {
              setPlanData((prevState) => {
                let plan = { ...prevState };
                plan.startedDate = date;
                plan.expectedCompletedDate = moment(date)
                  .add("364", "Days")
                  .format("DD-MM-YYYY");
                return plan;
              });
            }}
            withPortal
            todayButton="Today"
            dateFormat="dd MMM"
            showMonthDropdown
            minDate={new Date()}
            maxDate={new Date(new Date().getFullYear(), 11, 31)}
            customInput={<CustomInput />}
          />
        </Box>
        <Box
          sx={{
            width: "350px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              width: "180px",
              flexDirection: "column",
            },
          }}
        >
          <Typography sx={{ fontWeight: "800" }} variant="p" gutterBottom>
            {t("expectedTime")}
          </Typography>
          <Typography variant="p" gutterBottom>
            {planData.expectedCompletedDate}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "350px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              width: "180px",
              flexDirection: "column",
            },
          }}
        >
          <Typography sx={{ fontWeight: "800" }} variant="p" gutterBottom>
            {t("days")}
          </Typography>
          <Typography variant="p" gutterBottom>
            {planData?.totalDays}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "350px",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              width: "180px",
              flexDirection: "column",
            },
          }}
        >
          <Typography sx={{ fontWeight: "800" }} variant="p" gutterBottom>
            {t("readings")}
          </Typography>
          <Typography variant="p" gutterBottom>
            {planData?.totalReadings}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{
            marginTop: "3px",
            padding: "2px 10px",
            fontSize: 14,
            height: "30px",
            color: BLACK,
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "&:hover": {
              backgroundColor: BLACK + "0a",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
          onClick={handleClose}
        >
          {t("commonClose")}
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            marginTop: "3px",
            padding: "2px 10px",
            fontSize: 14,
            height: "30px",
            color: BLACK,
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "&:hover": {
              backgroundColor: BLACK + "0a",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
          onClick={() => {
            createFirebasePlan(planData);
          }}
        >
          {t("createPlan")}
        </Button>
      </DialogActions>
    </>
  );
};
export default CreatePlan;
