import { Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import { LIGHTGREY, DARKORANGE, WHITE } from "../../store/colorCode";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  doc,
  setDoc,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../firebase/firebaseConfig";
import { connect } from "react-redux";

const NewsLetterSection = ({ locale }) => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const signUp = async () => {
    if (email && userName) {
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!emailRegex.test(email)) {
        setAlert("error");
        setMessage(t("invalidEmailMsg"));
        return;
      }
      const emailList = [];
      try {
        const emailsCollection = collection(db, "emails");
        const querySnapshot = await getDocs(emailsCollection);
        querySnapshot.forEach((doc) => {
          emailList.push(doc.data());
        });
      } catch (error) {
        console.error("Error fetching existing data: ", error);
      }

      const emailExists = emailList.some(
        (existingEmail) => existingEmail.email === email
      );

      if (emailExists) {
        setAlert("error");
        setMessage(t("emailExistsMsg"));
        return;
      }

      const docData = {
        email: email,
        name: userName,
        created_time: new Date(),
      };
      try {
        await setDoc(doc(db, "emails", userName), docData);
        setAlert("success");
        setMessage(t("subscribeSuccessMsg"));
        setEmail("");
        setUserName("");
      } catch (error) {
        console.error("Error adding document: ", error);
        setAlert("error");
        setMessage(t("subscribeErrorMsg"));
      }
    } else {
      setAlert("error");
      setMessage(t("emptyNewsletterFormMsg"));
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert("");
    setMessage("");
  };

  return (
    <Box
      sx={{
        fontSize: { lg: 20, xs: 16 },
        textAlign: "center",
        backgroundColor: LIGHTGREY,
        paddingBottom: "20px",
      }}
    >
      <Box sx={{ padding: "40px 40px 0 40px" }}>
        <Box
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: { xs: locale === "ml" ? "24px" : "30px", sm: "2.5rem" },
            paddingBottom: "10px",
          }}
        >
          {t("newsLetterTitle")}
        </Box>
        <h5> {t("newsLetterCaption")}</h5>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingY: "40px",
        }}
      >
        <TextField
          required
          sx={{ width: "350px", padding: "10px" }}
          name="name"
          type="text"
          id="name"
          placeholder={t("userName")}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextField
          required
          sx={{ width: "350px", padding: "10px" }}
          id="email"
          name="email"
          placeholder={t("loginEmailPlaceHolder")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          variant="outlined"
          onClick={signUp}
          sx={{
            backgroundColor: DARKORANGE,
            color: WHITE,
            fontSize: "18px",
            height: "56px",
            width: "150px",
            marginY: "auto",
            borderColor: DARKORANGE,
            "&:hover": {
              backgroundColor: DARKORANGE,
              color: WHITE,
              border: "1px solid rgba(247,101,62,255)",
            },
          }}
        >
          {t("loginSubmit")}
        </Button>
      </Box>
      {alert ? (
        <Snackbar
          open={Boolean(alert)}
          autoHideDuration={8000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert variant="filled" onClose={handleClose} severity={alert}>
            {message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.local.locale,
  };
};

export default connect(mapStateToProps)(NewsLetterSection);
