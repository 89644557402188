import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { BLACK } from "../../store/colorCode";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";

const DeletePopup = ({ handleClose, deleteData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState("today");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <DialogTitle
        sx={{
          boxShadow: 4,
          padding: "10px 18px",
        }}
      >
        <Box
          sx={{
            marginBottom: "-10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h7" gutterBottom>
            {t("deleteHistory")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="delete-label"
            value={value}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="today"
              control={<Radio />}
              label={t("deleteTodayData")}
            />
            <FormControlLabel
              value="all"
              control={<Radio />}
              label={t("deleteAllData")}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          sx={{
            marginTop: "3px",
            padding: "2px 10px",
            fontSize: 14,
            height: "30px",
            color: BLACK,
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "&:hover": {
              backgroundColor: BLACK + "0a",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
          onClick={handleClose}
        >
          {t("commonClose")}
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            marginTop: "3px",
            padding: "2px 10px",
            fontSize: 14,
            height: "30px",
            color: BLACK,
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "&:hover": {
              backgroundColor: BLACK + "0a",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: 12,
            },
          }}
          onClick={() => {
            deleteData(value);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </>
  );
};
export default DeletePopup;
